import React, { useEffect } from 'react'
import { Container } from '../utils/Container'
import Select from 'react-select'
import { EotPart, IsbnParsed } from '../utils/Common/Common'
import { queryServer } from '../utils/queryServer'
import { useAllowedIsbns } from '../FindExercisePage/useAllowedIsbns'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { check, checkNotNull, nowSecondsUTC, transformEotPartsToString, transformStringToEotParts } from '../utils/utilFunctions'
import { Eot } from '../ExercisePage/Eot'
import { Button } from 'reactstrap'
import { uploadFile } from '../utils/uploadFile'
import { renderIsbnAreas, SelectedIsbnAndArea } from './SaveExerciseModal'
import { SelectIsbnBox } from '../FindExercisePage/SelectIsbnBox'

export const TrainingExercisesPage = () => {
    const allowedIsbns = useAllowedIsbns()
    const [selectedIsbnAndArea, setSelectedIsbnAndArea] = React.useState<SelectedIsbnAndArea>()
    const [exercisesForTraining, setExercisesForTraining] = React.useState<RequestResponseTypes['getOrderedExerciseIdsFromSolvedBook']['response']>()
    const [selectedIndex, setSelectedIndex] = React.useState<number>()
    const [exercise, setExercise] = React.useState<RequestResponseTypes['getExerciseWithMetadata']['response']>()

    const getOrderedExerciseIdsFromSelectedIsbn = async (isbn: IsbnParsed['isbn']) => {
        setExercisesForTraining(undefined)
        setExercise(undefined)
        const response = await queryServer(
            'getOrderedExerciseIdsFromSolvedBook',
            { isbn },
        )
        setExercisesForTraining(response)
    }

    const getExercise = async (exerciseId: string) => {
        setExercise(undefined)
        const response = await queryServer(
            'getExerciseWithMetadata',
            {
                exerciseId,
            },
        )
        setExercise(response)
    }

    useEffect(() => {
        if (selectedIsbnAndArea != null) {
            getOrderedExerciseIdsFromSelectedIsbn(selectedIsbnAndArea.isbn.isbn)
        }
    }, [selectedIsbnAndArea?.isbn.isbn])

    useEffect(() => {
        if (selectedIndex != null) {
            getExercise(checkNotNull(exercisesForTraining).exercises[selectedIndex].exerciseId)
        }
    }, [selectedIndex])

    const toggleMarkedForTraining = async (exerciseId: string, okForTraining: boolean) => {
        await queryServer(
            'updateExerciseMarkForTraining',
            {
                exerciseId,
                okForTraining,
            },
        )

        check(exercise != null && exercise.exerciseMetadata.id === exerciseId, "nddsa2")
        setExercise({
            ...exercise,
            exerciseMetadata: {
                ...exercise.exerciseMetadata,
                marked_for_training_at: okForTraining ? nowSecondsUTC() : null,
            },
        })

        check(exercisesForTraining != null, 'jkuhg')
        setExercisesForTraining({
            exercises: exercisesForTraining.exercises.map(e => {
                if (e.exerciseId === exerciseId) {
                    return {
                        ...e,
                        markedForTraining: okForTraining,
                    }
                } else {
                    return e
                }
            })
        })
    }

    const updateExercisePhotoUrl = async (exerciseId: string, photoUrl: string | null) => {
        await queryServer(
            'updateExercisePhotoUrl',
            {
                exerciseId,
                photoUrl,
            },
        )

        // isto ovde umesto reload set state gde treba
        // i proveri na backend sta jos se odradi kad se npr obrise slika
        location.reload()
    }

    const renderNumbers = () => {
        if (exercisesForTraining != null) {
            return <div className='mg-b-50'>
                <div className='mg-b-30' style={{
                    display: "flex",
                    flexWrap: "wrap"
                }}>
                    {exercisesForTraining.exercises
                        .map((e, index) => {
                            if (selectedIsbnAndArea?.area == null || e.inIsbnAreaId === selectedIsbnAndArea.area.id) {
                                return <span
                                    onClick={() => setSelectedIndex(index)}
                                    key={e.exerciseId}
                                    className='span-btn'
                                    style={{
                                        borderWidth: index === selectedIndex ? "4px" : undefined,
                                        fontWeight: index === selectedIndex ? "bold" : undefined,
                                        backgroundColor: e.markedForTraining ? "#fff4c7" : undefined,
                                        color: e.markedForTraining ? 'black' : undefined,
                                        borderColor: e.hasTextPhoto ? "orange" : undefined,
                                    }}
                                >
                                    {index + 1}
                                </span>
                            } else {
                                return null
                            }
                        })}
                </div>
            </div>
        }
    }

    const renderExercisePhotoWithEdit = () => {
        if (exercise && exercise.exerciseMetadata.exercise_text_photo_url != null) {
            return <div style={{
                margin: "40px 0",
            }}>
                <img
                    className='img-in-eot-solution'
                    src={exercise.exerciseMetadata.exercise_text_photo_url}
                    alt="No, or bad image..."
                />
                <div className='mg-t-20'>
                    <span
                        className='span-btn'
                        style={{ color: "red" }}
                        onClick={() => {
                            updateExercisePhotoUrl(exercise.exerciseMetadata.id, null)
                        }}
                    >
                        Delete photo
                    </span>
                </div>
            </div>
        } else if (exercise && exercise.exerciseMetadata.exercise_text_photo_url === null) {
            return <div className='mg-t-20'>
                Upload exercise text photo
                <input
                    id="filePicker"
                    style={{}}
                    type={"file"}
                    onChange={async (e) => {
                        if (e.target.files) {
                            const uploadResult = await uploadFile(e.target.files[0], "exercise-text-images")

                            if (uploadResult != null) {
                                await updateExercisePhotoUrl(
                                    exercise.exerciseMetadata.id,
                                    uploadResult.fileUrl,
                                )
                            }
                        }
                    }}
                />
            </div>
        } else {
            return null
        }
    }

    const canBeMarkedForTraining = exercise != null
        && exercise.exerciseMetadata.exercise_text_photo_url != null
        && transformEotPartsToString(exercise.textSolution) != null

    if (allowedIsbns == null) {
        return <h5>please wait...</h5>
    }

    return <>
        <Container
            title='training exercise'
            withFooter={{
                withFooter: false,
                setFooterToBootom: true,
            }}
            whiteWrapper={{
                whiteWrapper: true,
                expandWhiteWrapperToWholeScreen: false,
            }}
        >
            <div className="isbn-area-chose mg-b-20 mg-t-20">
                <SelectIsbnBox
                    allIsbns={allowedIsbns}
                    selectedIsbn={selectedIsbnAndArea?.isbn}
                    selectIsbn={isbnParsed => setSelectedIsbnAndArea({
                        isbn: isbnParsed,
                        area: null
                    })}
                />
                {selectedIsbnAndArea?.isbn && renderIsbnAreas(selectedIsbnAndArea.isbn.areas, setSelectedIsbnAndArea, selectedIsbnAndArea.area?.id)}
            </div>

            {selectedIsbnAndArea != null && renderNumbers()}
            {selectedIsbnAndArea != null && <hr />}

            {exercise != null && <>
                <h6>Order number in isbn: {exercise.exerciseMetadata.order_number_in_isbn}</h6>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '40px',
                }}>
                    <h2>
                        Marked for training: {exercise.exerciseMetadata.marked_for_training_at == null ? <span style={{ color: "red" }}>NO</span> : <span style={{ color: "green" }}>YES</span>}
                    </h2>
                    {!canBeMarkedForTraining && <h6 style={{ color: 'red' }}>
                        Should not be marked for training (either missing image of a text, or existence of an image in solution)
                    </h6>}
                    {canBeMarkedForTraining && <Button
                        color={`${exercise?.exerciseMetadata.marked_for_training_at != null ? 'secondary' : 'primary'}`}
                        onClick={() => {
                            toggleMarkedForTraining(
                                exercise.exerciseMetadata.id,
                                exercise.exerciseMetadata.marked_for_training_at == null ? true : false,
                            )
                        }}
                    >
                        {exercise?.exerciseMetadata.marked_for_training_at == null ? 'Mark for training' : 'Unmark for training'}
                    </Button>}
                </div>
                {renderExercisePhotoWithEdit()}
            </>}

        </Container>
        {exercise != null && <div style={{
            width: "100%",
            backgroundColor: "white",
            border: "1px solid green",
            display: "flex",
            padding: '5px',
        }}>
            <div style={{ width: "30%", border: "1px solid orange", padding: '10px' }}>
                <Eot
                    kind='exercise'
                    eotId={exercise.exerciseMetadata.id}
                    textSolution={exercise.textSolution}
                    text_public={exercise.exerciseMetadata.exercise_text_public}
                    video_solution={exercise.video_solution?.videoMetadata ?? null}
                    subjectId="not_used_ak9c"
                    initialyQueriedComments={undefined}
                    hideEdit={true}
                    hideSaveButton={true}
                    row=''
                />
            </div>
            <div style={{ width: "30%", border: "1px solid green", padding: '10px' }}>
                {renderWithTransformedSolution(
                    exercise.exerciseMetadata.exercise_text_public,
                    exercise.textSolution,
                )}
            </div>
            <div style={{ width: "30%", border: "1px solid pink", padding: '10px' }}>
                {transformEotPartsToString(exercise.exerciseMetadata.exercise_text_public)}
                <hr />
                {transformEotPartsToString(exercise.textSolution)}
            </div>
        </div>}
    </>
}

const renderWithTransformedSolution = (
    text: EotPart[],
    solution: EotPart[],
) => {
    let transformedSolution: EotPart[]
    const transformedToString = transformEotPartsToString(solution)
    if (transformedToString == null) {
        transformedSolution = [{
            id: "unused_cdsa787ay",
            order_number: 0,
            content: {
                type: "text",
                text: ">>> Solution can't be transformed <<<"
            },
        }]
    } else {
        transformedSolution = transformStringToEotParts(transformedToString)
    }

    return <Eot
        kind='exercise'
        eotId={"UNUSED_HGB7Aj"}
        textSolution={transformedSolution}
        text_public={text}
        video_solution={null}
        subjectId="not_used_ak9Rc"
        initialyQueriedComments={undefined}
        hideEdit={true}
        hideSaveButton={true}
        row=''
    />
}
