import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { SavedCollectionsHelper } from '../SavedCollectionsPage/SavedCollectionsPage'
import { Container } from '../utils/Container'

type Props = {
    active: boolean
}

export const SavedCollections_WebView = ({
    active,
}: Props) => {
    const [searchParams] = useSearchParams()

    const userId = searchParams.get("userId")

    let content
    if (userId == null) {
        content = <h3>Unknown user</h3>
    } else {
        content = <SavedCollectionsHelper userId={userId} />
    }

    return <div style={{ display: active ? undefined : 'none' }}>
        {content}
        {/* <Container
            title="Saved collection page"
            // className="home-page"
            withFooter={{
                withFooter: false,
                setFooterToBootom: false,
            }}
            whiteWrapper={{
                whiteWrapper: true,
                expandWhiteWrapperToWholeScreen: false,
            }}
            withHeader={false}
            applyMargins={false}
        >
            {content}
        </Container> */}
    </div>
}
