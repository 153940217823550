import React from 'react'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { useLocalLanguage } from '../utils/useLocalLanguage'
import { queryServer } from '../utils/queryServer'
import { Loader } from '../utils/Loader'
import { HomeData } from '../HomePage/HomeData'
import { assertNever } from '../utils/utilFunctions'
import { Container } from '../utils/Container'

type Props = {
    active: boolean
}

export const Eots_WebView = ({
    active,
}: Props) => {
    const [homeData, setHomeData] = React.useState<RequestResponseTypes["home"]["response"]>()

    const { inLocalLanguage } = useLocalLanguage()

    const getHomeData = async () => {
        const data = await queryServer(
            'home',
            undefined,
        )
        setHomeData(data)
    }

    React.useEffect(() => {
        getHomeData()
    }, [])

    let content
    if (homeData == null) {
        content = <Loader />
    } else if (homeData.type === 'no_data') {
        content = <h2>{inLocalLanguage("No data")}</h2>
    } else if (homeData.type === 'single_option') {
        content = <HomeData
            subjectsAndGrades={[{
                ...homeData.subject,
                grades: [{ ...homeData.grade }],
            }]}
            singleOptionData={{
                exercise: homeData.exercise,
                theory: homeData.theory,
                exerciseTests: homeData.exerciseTests,
            }}
        />
    } else if (homeData.type === 'multiple_options') {
        content = <HomeData
            subjectsAndGrades={homeData.subjectsAndGrades}
            singleOptionData={undefined}
        />
    } else {
        assertNever(homeData)
    }

    return <div
        style={{ display: active ? undefined : 'none' }}
        className='pd-t-10'
    >
        {content}
        {/* <Container
            title={inLocalLanguage('Home page')}
            // className="home-page"
            withFooter={{
                withFooter: true,
                setFooterToBootom: true,
            }}
            whiteWrapper={{
                whiteWrapper: true,
                expandWhiteWrapperToWholeScreen: false,
            }}
            withHeader={false}
            applyMargins={false}
        >
            {content}
        </Container> */}
    </div>
}
