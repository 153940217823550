import React from 'react'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { queryServer } from '../utils/queryServer'
import {
    FormGroup,
    Label,
    Input,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from 'reactstrap'
import { nanoid } from 'nanoid'
import { assertNever, check, checkNotNull } from '../utils/utilFunctions'
import * as _ from "lodash"

type Props = {
    subjectId: string
    gradesForSubject: RequestResponseTypes["getGradesForSubject"]["response"]
}

type OrganizationWithStatuses = {
    id: string
    order_number: number
    deep_level: number
    parent_id: string | null
    area_name: string
    assigned_theory_id: null | {
        id: string
        status: "shouldSave" | "saved" | "shouldDelete"
    }
    status: "shouldSave" | "saved" | "shouldDelete"
}[]

export const SubjectAuthority_Theory = ({ subjectId, gradesForSubject }: Props) => {
    const [selectedGrade, setSelectedGrade] = React.useState<{ id: string, grade_in_local: string }>()

    const [allSubjectTheoriesMetadataOrigin, setAllSubjectTheoriesMetadataOrigin] = React.useState<RequestResponseTypes["getAllSubjectTheoriesMetadataOrigin"]["response"]>()

    const getAllSubjectTheoriesMetadataOrigin = async () => {
        const result = await queryServer(
            'getAllSubjectTheoriesMetadataOrigin',
            { subjectId },
        )
        setAllSubjectTheoriesMetadataOrigin(result)
    }

    React.useEffect(() => {
        getAllSubjectTheoriesMetadataOrigin()
    }, [])

    // areaOrganization will hold the data about what needs to be done (delete/create etc)
    const [areaOrganizationWithStatuses, setAreaOrganizationWithStatuses] = React.useState<OrganizationWithStatuses>()

    const calcAreaOrganizationAndSetState = (organization: RequestResponseTypes["getAreaOrganizationForGrade_Theory"]["response"]) => {
        setAreaOrganizationWithStatuses(organization.data.map(it => ({
            ...it,
            status: 'saved',
            assigned_theory_id: it.assigned_theory_id != null ? {
                id: it.assigned_theory_id,
                status: 'saved',
            } : null,
        })))
    }

    const getAreaOrganizationForGrade = async (gradeId: string) => {
        const organization = await queryServer(
            'getAreaOrganizationForGrade_Theory',
            { gradeId },
        )

        calcAreaOrganizationAndSetState(organization)
    }

    React.useEffect(() => {
        if (selectedGrade != null) {
            getAreaOrganizationForGrade(selectedGrade.id)
        }
    }, [selectedGrade])

    const saveAreaOrganizationForGrade_Theory = async (areaOrganizationWithStatuses: OrganizationWithStatuses) => {
        const areaOrganization_: RequestResponseTypes["saveAreaOrganizationForGrade_Theory"]["request"]['areaOrganization'] = []

        areaOrganizationWithStatuses.forEach(area => {
            let itsAssigned_theory_id: null | {
                theoryId: string
                todo: 'save' | 'delete'
            } = null
            if (area.assigned_theory_id?.status === 'shouldDelete') {
                itsAssigned_theory_id = {
                    theoryId: area.assigned_theory_id.id,
                    todo: 'delete',
                }
            } else if (area.assigned_theory_id?.status === 'shouldSave') {
                itsAssigned_theory_id = {
                    theoryId: area.assigned_theory_id.id,
                    todo: 'save',
                }
            }

            if (area.status === 'saved') {
                if (itsAssigned_theory_id != null) {
                    areaOrganization_.push({
                        id: area.id,
                        order_number: area.order_number,
                        deep_level: area.deep_level,
                        parent_id: area.parent_id,
                        area_name: area.area_name,
                        // handleAssignedTheoryArea je za one oblasti gde samo assigned_theory_id treba da se cacne
                        todo: 'handleAssignedTheoryArea',
                        assigned_theory_id: itsAssigned_theory_id,
                    })
                }
            } else if (area.status === 'shouldDelete') {
                check(itsAssigned_theory_id == null || itsAssigned_theory_id.todo === 'delete')
                areaOrganization_.push({
                    id: area.id,
                    order_number: area.order_number,
                    deep_level: area.deep_level,
                    parent_id: area.parent_id,
                    area_name: area.area_name,
                    todo: 'delete',
                    assigned_theory_id: itsAssigned_theory_id,
                })
            } else if (area.status === 'shouldSave') {
                check(itsAssigned_theory_id == null || itsAssigned_theory_id.todo === 'save')
                areaOrganization_.push({
                    id: area.id,
                    order_number: area.order_number,
                    deep_level: area.deep_level,
                    parent_id: area.parent_id,
                    area_name: area.area_name,
                    todo: 'save',
                    assigned_theory_id: itsAssigned_theory_id,
                })
            } else {
                assertNever(area.status)
            }
        })

        const organization = await queryServer(
            'saveAreaOrganizationForGrade_Theory',
            {
                gradeId: checkNotNull(selectedGrade, 'Hjus7Uyu').id,
                areaOrganization: areaOrganization_,
            },
        )

        calcAreaOrganizationAndSetState(organization)
        location.reload()
    }

    const [form_addNewAreaInOrganization, setForm_addNewAreaInOrganization] = React.useState<{
        addAreaIn: string
        grade_area_order_number: number
        grade_area_deep_level: number
        grade_area_parent_id: string | null
        grade_area_area_name: string
    }>()

    const [selectedForAssigning, setSelectedForAssigning] = React.useState<{
        theoryId: string | null
        areaFromOrganizationId: string | null
    }>({ theoryId: null, areaFromOrganizationId: null })

    if (allSubjectTheoriesMetadataOrigin == null) {
        return <h3>please wait...</h3>
    }

    const renderAddNewAreaInOrganizationForm_THEORY = () => {
        if (form_addNewAreaInOrganization != null) {
            return <div>
                <h4>Add area in {form_addNewAreaInOrganization.addAreaIn}</h4>
                <FormGroup>
                    <Label>Area name</Label>
                    <Input
                        type="text"
                        placeholder="area name..."
                        onChange={(e) => setForm_addNewAreaInOrganization({
                            ...form_addNewAreaInOrganization,
                            grade_area_area_name: e.target.value,
                        })}
                        value={form_addNewAreaInOrganization.grade_area_area_name}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Order number</Label>
                    <Input
                        type="number"
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        placeholder="order number..."
                        onChange={(e) => setForm_addNewAreaInOrganization({
                            ...form_addNewAreaInOrganization,
                            grade_area_order_number: +e.target.value,
                        })}
                        value={form_addNewAreaInOrganization.grade_area_order_number}
                    />
                </FormGroup>
                <Button onClick={() => {
                    setAreaOrganizationWithStatuses(prev => {
                        if (
                            form_addNewAreaInOrganization.grade_area_area_name !== '' &&
                            form_addNewAreaInOrganization.grade_area_order_number > 0
                        ) {
                            if (prev != null) {
                                return prev.concat({
                                    id: nanoid(),
                                    status: 'shouldSave',
                                    order_number: form_addNewAreaInOrganization.grade_area_order_number,
                                    parent_id: form_addNewAreaInOrganization.grade_area_parent_id,
                                    area_name: form_addNewAreaInOrganization.grade_area_area_name,
                                    deep_level: form_addNewAreaInOrganization.grade_area_deep_level,
                                    assigned_theory_id: null,
                                })
                            } else {
                                console.log('Strange behavior... check this 5BnBnbytui6456543')
                                return prev
                            }
                        } else {
                            console.log('not good filled hGHYH67shgg')
                            return prev
                        }
                    })
                    setForm_addNewAreaInOrganization(undefined)
                }}>Ok</Button>
                <Button onClick={() => setForm_addNewAreaInOrganization(undefined)}>Cancel</Button>
            </div>
        } else {
            return undefined
        }
    }

    const renderAreasOrganization_THEORY = (areaOrganizationWithStatuses: OrganizationWithStatuses) => {
        if (areaOrganizationWithStatuses.length === 0) {
            return <h3>The grade has no organization yet</h3>
        } else {
            return <div>
                <div className="rendered-isbn-organization">
                    {_.sortBy(areaOrganizationWithStatuses, (it) => it.order_number)
                        .map(area => {
                            let color
                            if (area.status === 'saved') {
                                color = 'black'
                            } else if (area.status === 'shouldDelete') {
                                color = 'red'
                            } else if (area.status === 'shouldSave') {
                                color = 'green'
                            } else {
                                assertNever(area.status)
                            }

                            // for now request to delete old assigned before assigning the new one
                            const allowSelectingForAssigning = area.assigned_theory_id == null // || area.assignedTheoryId.status === 'shouldDelete'

                            let selectForAssignColor = '#e3e3e3'
                            if (allowSelectingForAssigning) {
                                if (selectedForAssigning?.areaFromOrganizationId === area.id) {
                                    selectForAssignColor = 'green'
                                } else {
                                    selectForAssignColor = 'black'
                                }
                            }

                            return <div key={area.id} style={{ marginLeft: `${area.deep_level * 10 + 10}px`, marginBottom: '15px' }}>
                                <div>
                                    <span
                                        onClick={() => console.log('nothingy767')}
                                        style={{ color }}
                                    >
                                        {area.area_name}
                                    </span>
                                    <span
                                        className='span-btn'
                                        onClick={() => {
                                            setForm_addNewAreaInOrganization({
                                                addAreaIn: area.area_name,
                                                grade_area_order_number: -1,
                                                grade_area_deep_level: area.deep_level + 1,
                                                grade_area_parent_id: area.id,
                                                grade_area_area_name: '',
                                            })
                                        }}
                                    >
                                        Dodaj podoblast
                                    </span>
                                    <span
                                        className='span-btn'
                                        style={{ color: selectForAssignColor }}
                                        onClick={() => {
                                            if (allowSelectingForAssigning) {
                                                setSelectedForAssigning(prev => {
                                                    return {
                                                        theoryId: prev.theoryId,
                                                        areaFromOrganizationId: prev.areaFromOrganizationId === area.id ? null : area.id,
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        Toggle select for assigning
                                    </span>
                                    <span
                                        className='span-btn'
                                        style={{ color: 'red' }}
                                        onClick={() => {
                                            // logika je i losa i netacna (ova provera nije dobra, u slucaju kada se radi toggle umesto delete.)
                                            // isto i za exercise theory org nije dobro. kasnije treba da se sredi... !!!!!!
                                            // (ne znam sta znaci komentar, nisam se udubljivao, vidi kasnije ako treba (9.jul 2023)) Aham, ipak mislim da znam:
                                            // Ideja je da ovo dugme nije samo za brisanje, vec je ovo ustvari "Toggle" dugme. Tako da ne moze da se proverava
                                            // allowToDelete, vec treba da se proveri "allowToToggle". Jer ima razlike da li hoces da obrises ili da
                                            // vratis na "saved".
                                            //
                                            // allow to delete only if it doesnt have any children ("saved" or "toCreate"),
                                            // and if it doesnt have assigned ("saved" or "toCreate") theory
                                            const allowToDelete = areaOrganizationWithStatuses.find(it => {
                                                return it.parent_id === area.id && (it.status === 'saved' || it.status === 'shouldSave')
                                            }) == null && (area.assigned_theory_id == null || area.assigned_theory_id.status === 'shouldDelete')

                                            if (allowToDelete) {
                                                const newAreas: OrganizationWithStatuses = []
                                                areaOrganizationWithStatuses.forEach(it => {
                                                    if (it.id !== area.id) {
                                                        newAreas.push(it)
                                                    } else {
                                                        if (it.status === 'saved') {
                                                            newAreas.push({
                                                                ...it,
                                                                status: 'shouldDelete',
                                                            })
                                                        } else if (it.status === 'shouldDelete') {
                                                            // toggle (nije dobra provera za "allowTODelete" za ovaj slucaj, ali nema veze za sad, sredice se...)
                                                            // (zasto nije dobra, ne znam. nisam se udubljivao (9. jul 2023.)). Ipak znam mozda: vidi gore komentar
                                                            newAreas.push({
                                                                ...it,
                                                                status: 'saved',
                                                            })
                                                        } else if (it.status === 'shouldSave') {
                                                            // don't do nothing (this is actually deleting)
                                                        } else {
                                                            assertNever(it.status)
                                                        }
                                                    }
                                                })

                                                setAreaOrganizationWithStatuses(newAreas)
                                            } else {
                                                console.log('not allowed to delete. Area should be empty and without assigned theory 7udsayQ')
                                            }
                                        }}
                                    >
                                        Delete
                                    </span>
                                </div>
                                {area.assigned_theory_id != null && <div>
                                    <span
                                        style={{ color: area.assigned_theory_id.status === 'saved' ? 'black' : (area.assigned_theory_id.status === 'shouldDelete' ? 'red' : 'green'), margin: '4px' }}
                                        onClick={() => {
                                            setAreaOrganizationWithStatuses(areaOrganizationWithStatuses.map(it => {
                                                if (it.id === area.id) {
                                                    return {
                                                        ...it,
                                                        assigned_theory_id: it.assigned_theory_id!.status === 'saved' ? {
                                                            id: it.assigned_theory_id!.id,
                                                            status: 'shouldDelete',
                                                        } : it.assigned_theory_id!.status === 'shouldDelete' ? {
                                                            id: it.assigned_theory_id!.id,
                                                            status: 'saved',
                                                        } : null
                                                    }
                                                } else {
                                                    return it
                                                }
                                            }))
                                        }}
                                    >
                                        {allSubjectTheoriesMetadataOrigin.data.find(it => it.id === area.assigned_theory_id!.id)!.title}
                                    </span>
                                </div>}
                            </div>
                        })}
                </div>
            </div>
        }
    }

    const renderRightBoxDetails = () => {
        if (areaOrganizationWithStatuses == null) {
            return <div>choose a grade to see organization</div>
        } else {
            return <div>
                {renderAddNewAreaInOrganizationForm_THEORY()}
                {renderAreasOrganization_THEORY(areaOrganizationWithStatuses)}
                <span
                    className='span-btn'
                    onClick={() => {
                        setForm_addNewAreaInOrganization({
                            addAreaIn: 'Top-level',
                            grade_area_order_number: -1,
                            grade_area_deep_level: 0,
                            grade_area_parent_id: null,
                            grade_area_area_name: '',
                        })
                    }}
                >
                    Add top-level area
                </span>
                <div>
                    <Button
                        onClick={() => saveAreaOrganizationForGrade_Theory(areaOrganizationWithStatuses)}
                    >
                        Save THEORY areas organization
                    </Button>
                </div>
            </div>
        }
    }

    return <div className='verticaly-boxes'>
        <div className='left-box'>
            {allSubjectTheoriesMetadataOrigin.data.map(theory => {
                return <div
                    key={theory.id}
                    style={{ color: selectedForAssigning?.theoryId === theory.id ? 'green' : 'black' }}
                >
                    {theory.title} <span className='span-btn' onClick={() => {
                        setSelectedForAssigning(prev => {
                            return {
                                areaFromOrganizationId: prev.areaFromOrganizationId,
                                theoryId: prev.theoryId === theory.id ? null : theory.id,
                            }
                        })
                    }}>Toggle select for assigning</span>
                </div>
            })}

            <div>
                <Button
                    disabled={selectedForAssigning.areaFromOrganizationId == null || selectedForAssigning.theoryId == null}
                    onClick={() => {
                        check(selectedForAssigning.areaFromOrganizationId != null && selectedForAssigning.theoryId != null, 'erUU8d3mv0')
                        setAreaOrganizationWithStatuses(areaOrganizationWithStatuses!.map(it => {
                            if (it.id === selectedForAssigning.areaFromOrganizationId) {
                                if (it.assigned_theory_id == null) {
                                    return {
                                        ...it,
                                        assigned_theory_id: {
                                            id: selectedForAssigning.theoryId!,
                                            status: 'shouldSave',
                                        }
                                    }
                                } else {
                                    console.log('until old assigned is not realy deleted, it cannot be assigned the new one. lkLKiodsik98Ik')
                                    return it
                                }
                            } else {
                                return it
                            }
                        }))
                    }}
                >
                    Assign theory
                </Button>
            </div>
        </div>
        <div className='right-box'>
            <FormGroup>
                <Label for="difficulty">Chose grade</Label>
                <UncontrolledDropdown id="difficulty">
                    <DropdownToggle caret outline>
                        {selectedGrade?.grade_in_local ?? "Chose the grade"}
                    </DropdownToggle>
                    <DropdownMenu>
                        {_.sortBy(gradesForSubject.grades, (it) => it.order_number)
                            .map((grade, index) => <DropdownItem
                                key={index}
                                onClick={() => {
                                    setSelectedGrade(grade)
                                }}
                            >
                                {grade.grade_in_local}
                            </DropdownItem>)}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </FormGroup>
            {renderRightBoxDetails()}
        </div>
    </div>
}
