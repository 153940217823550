import React from 'react'
import { Container } from "../utils/Container"
import { queryServer } from "../utils/queryServer"
import { useParams, useNavigate } from 'react-router-dom'
import { assertNever, capitalizeFirstLetter, check } from "../utils/utilFunctions"
import { ExerciseOrTheory } from '../utils/Common/Common'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { EotTextInList } from '../utils/EotTextInList'
import { EditCollectionModal } from './EditCollectionModal'
import { AppContext } from '../contexts/AppContext'
import { DeleteCollectionModal } from './DeleteCollectionModal'
import { useModal } from '../utils/useModal'
import { Loader } from '../utils/Loader'
import { Popover1 } from '../utils/Popover1'
import { useLocalLanguage } from '../utils/useLocalLanguage'
import { DeleteCollectionItemModal } from './DeleteCollectionItem'
import _ from 'lodash'

const SavedCollectionX = ({ collectionId }: { collectionId: string }) => {
    const [savedCollection, setSavedCollection] = React.useState<RequestResponseTypes['savedCollection']['response']>()
    const [sendExerciseModal, setSendExerciseModal] = React.useState(false)
    const deleteCollectionModal = useModal(DeleteCollectionModal)
    const deleteCollectionItemModal = useModal(DeleteCollectionItemModal)
    const editCollectionModal = useModal(EditCollectionModal)
    const { gMyId } = React.useContext(AppContext)

    const navigate = useNavigate()

    const { inLocalLanguage } = useLocalLanguage()

    React.useEffect(() => {
        getCollection(collectionId)
    }, [collectionId])

    const getCollection = async (collectionId: string) => {
        setSavedCollection(undefined)
        setSendExerciseModal(false)
        editCollectionModal.close()
        deleteCollectionModal.close()
        const result = await queryServer(
            'savedCollection',
            {
                collectionId,
            },
        )
        setSavedCollection(result)
    }

    const choseEot = (eotId: string, eotType: ExerciseOrTheory) => {
        if (eotType === 'theory') {
            navigate(`/theory/${eotId}`)
        } else if (eotType === 'exercise') {
            navigate(`/exercise/${eotId}`)
        } else {
            assertNever(eotType)
        }
    }

    let content
    if (savedCollection == null) {
        content = <Loader />
    } else if (savedCollection.message === 'private_from_other_user') {
        content = <h2>{inLocalLanguage('Not access to this private collection')}</h2>
    } else if (savedCollection.message === 'not_exist') {
        content = <h2>{inLocalLanguage('Collection doesn\'t exist')}</h2>
    } else if (savedCollection.savedCollection.itemsInCollection.length === 0) {
        content = <h2>{inLocalLanguage('Empty collection')}</h2>
    } else {
        content = <>
            <div className="whole-bucket">
                <div className="collection-heading">
                    <div className="title-description">
                        <p>
                            {savedCollection.savedCollection.created_by.id === gMyId ?
                                inLocalLanguage('Your saved collection') :
                                `${inLocalLanguage('Saved collection of user ')} ${savedCollection.savedCollection.created_by.firstname} ${savedCollection.savedCollection.created_by.lastname}`}
                        </p>
                        <h1 style={{
                            color: 'rgb(27, 132, 231)',
                        }}>{capitalizeFirstLetter(savedCollection.savedCollection.collectionTitle)}</h1>
                        <p style={{ color: '#6c757d' }}>{savedCollection.savedCollection.collectionDescription}</p>
                    </div>
                    <div className="settings-send">
                        {savedCollection.savedCollection.created_by.id === gMyId && (<div style={{
                            color: 'rgb(27, 132, 231)',
                            fontSize: "180%",
                        }}>
                            <Popover1
                                htmlId={savedCollection.savedCollection.collectionId}
                                renderPopoverBody={(setOpen) => {
                                    return <div className='delete-collection-popover-body'>
                                        <div className='option' onClick={(e) => {
                                            editCollectionModal.show({
                                                collectionId: savedCollection.savedCollection.collectionId,
                                                currIsPrivate: savedCollection.savedCollection.isPrivate,
                                                currCollectionDescription: savedCollection.savedCollection.collectionDescription,
                                                currCollectionTitle: savedCollection.savedCollection.collectionTitle,
                                                afterSave: (
                                                    collectionId,
                                                    newIsPrivate,
                                                    newCollectionTitle,
                                                    newCollectionDescription,
                                                ) => {
                                                    setSavedCollection(prev => {
                                                        check(prev != null && prev.message === "ok", 'ayhJ88V')
                                                        return {
                                                            message: "ok",
                                                            savedCollection: {
                                                                ...prev.savedCollection,
                                                                isPrivate: newIsPrivate,
                                                                collectionTitle: newCollectionTitle,
                                                                collectionDescription: newCollectionDescription,
                                                            }
                                                        }

                                                    })
                                                },
                                            })
                                            setOpen(false)
                                            e.stopPropagation()
                                        }}>
                                            <i className='icon ion-edit' style={{ fontSize: "120%" }} /> {inLocalLanguage('Edit')}
                                        </div>
                                        <div className='option' onClick={(e) => {
                                            deleteCollectionModal.show({
                                                collectionId: savedCollection.savedCollection.collectionId,
                                                collectionTitle: savedCollection.savedCollection.collectionTitle,
                                                setSavedSortedCollectionsAndOwner: () => { },
                                                navigateToSavedCollectionsAfterDelete: true,
                                            })
                                            setOpen(false)
                                            e.stopPropagation()
                                        }}>
                                            <i className='icon ion-trash-b' style={{ fontSize: "120%" }} /> {inLocalLanguage("Delete")}
                                        </div>
                                    </div>
                                }}
                            />

                        </div>)}
                        {/* <span className="send-all-from-bucket" onClick={() => setSendExerciseModal(true)}>
                            Send all items from collection &nbsp;
                            <i className="fa fa-send"></i>
                        </span> */}
                    </div>
                </div>
                <div>
                    {_.sortBy(savedCollection.savedCollection.itemsInCollection, it => -it.addedTs).map((it, i) => {
                        return <div style={{ position: 'relative' }} key={it.itemId}>
                            <EotTextInList
                                eotText={it.text}
                                difficulty={undefined}
                                area={undefined}
                                exerciseOrderNumberInList={i + 1}
                                onClick={() => choseEot(it.itemId, it.itIs)}
                            />
                            <div
                                onClick={() => deleteCollectionItemModal.show({
                                    collectionId: collectionId,
                                    itemId: it.itemId,
                                    itIs: it.itIs,
                                    setSavedCollection: setSavedCollection,
                                })}
                                style={{
                                    position: 'absolute',
                                    right: '8px',
                                    top: '4px',
                                    cursor: 'pointer',
                                    padding: '4px',
                                }}
                            >
                                <i className='icon ion-trash-b' style={{ fontSize: "120%" }} />
                            </div>
                        </div>
                    })}
                </div>
            </div>
            {/* <SendEntitiesModal
            modalIsOpen={sendExerciseModal}
            toggleModal={() => setSendExerciseModal(!sendExerciseModal)}
            entitiesToSend={bucketDoc.savedInBucket}
            messagePreview="[collection]"
        /> */}
            {editCollectionModal && editCollectionModal.render()}
            {deleteCollectionModal && deleteCollectionModal.render()}
            {deleteCollectionItemModal && deleteCollectionItemModal.render()}
        </>
    }

    return <Container
        title="Saved collection page"
        className="home-page"
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
    >
        {content}
    </Container>
}

export const SavedCollection = () => {
    const { collectionId } = useParams()

    let content
    if (collectionId == null) {
        content = <h3>Unknown collection ID</h3>
    } else {
        content = <SavedCollectionX collectionId={collectionId} />
    }

    return content
}
