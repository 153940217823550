import React from "react"
import { queryServer } from "../utils/queryServer"
import { RequestResponseTypes } from "../utils/Common/RequestResponseTypes"
import { AreaOrganizationForGrade } from "./SubjectAuthority_Test"
import * as _ from "lodash"
import { useModal } from "../utils/useModal"
import { DeleteTestModal } from "./DeleteTestModal"

type Props = {
    selectedGrade: {
        id: string
        grade_in_local: string
    }
    gradeAreaOrganization: AreaOrganizationForGrade
}

export const GetTests = ({ selectedGrade, gradeAreaOrganization }: Props) => {
    const [tests, setTests] = React.useState<RequestResponseTypes["getTestsForGrade"]["response"]['tests']>()

    const deleteTestModal = useModal(DeleteTestModal)

    const getTestsForGrade = async () => {
        const result = await queryServer(
            'getTestsForGrade',
            {
                gradeId: selectedGrade.id
            },
        )

        setTests(result.tests)
    }

    React.useEffect(() => {
        getTestsForGrade()
    }, [])

    return <>
        <div style={{ marginTop: '20px', border: '2px solid orange', padding: '20px' }}>
            <div style={{ marginTop: '10px' }}>
                <div style={{ padding: '10px' }}>
                    {_.sortBy(tests, (test) => test.testOrderNumber).map((test) => <div
                        key={test.testId}
                        className='mg-b-30'
                        style={{ borderBottom: '1px solid #868ba1', position: 'relative' }}
                    >
                        <h4>{test.testOrderNumber}. {test.testTitle}</h4>
                        <p>{test.testDescription}</p>

                        {_.sortBy(test.exercises, (exercise) => exercise.exerciseOrderNumber).map(exercise => <div
                            key={exercise.exerciseOrderNumber}
                            className='mg-b-10'
                        >
                            Exercise {exercise.exerciseOrderNumber} : {exercise.rules.map((rule, index) => <div key={index}>
                                <span>{rule.probability}% </span>
                                <span>{gradeAreaOrganization.find(area => area.areaId === rule.gradeAreaId)?.area_name} - </span>
                                <span>{rule.difficulty} </span>
                            </div>)}
                        </div>)}
                        <div
                            onClick={() => deleteTestModal.show({
                                testId: test.testId,
                                testTitle: test.testTitle
                            })}
                            style={{
                                position: 'absolute',
                                right: '8px',
                                top: '4px',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            <i className='icon ion-trash-b' style={{ fontSize: "120%" }} />
                        </div>
                    </div>)}
                    {deleteTestModal.render()}
                </div>
            </div>
        </div>
    </>
}
