import React from 'react'
import { Container } from '../utils/Container'
import { useParams, useNavigate } from 'react-router-dom'
import { queryServer } from '../utils/queryServer'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { getProfileImageURL, onProfileImageError } from "../Header/Header"
import { AppContext } from '../contexts/AppContext'
import queryString from 'query-string'

const SubjectPrivateClassesX = ({ subjectId }: { subjectId: string }) => {
    const [subjectProfessors, setSubjectProfessors] = React.useState<RequestResponseTypes['getSubjectProfessors']['response']>()

    const { gMyId } = React.useContext(AppContext)
    const navigate = useNavigate()

    React.useEffect(() => {
        getSubjcetProfessors(subjectId)
    }, [subjectId])

    const getSubjcetProfessors = async (subjectId: string) => {
        setSubjectProfessors(undefined)

        const result = await queryServer(
            'getSubjectProfessors',
            {
                subjectId,
            },
        )
        setSubjectProfessors(result)
    }

    if (subjectProfessors == null) {
        return <h2>Please wait...</h2>
    } else if (subjectProfessors.length === 0) {
        return <h2>There is no professors for this subject</h2>
    } else {
        return <Container
            title="Subject Professors page"
            className="home-page"
            withFooter={{
                withFooter: true,
                setFooterToBootom: true,
            }}
            whiteWrapper={{
                whiteWrapper: true,
                expandWhiteWrapperToWholeScreen: true,
            }}
        >
            <div>
                {subjectProfessors.map(it => {
                    return <div
                        key={it.user_id}
                        className="card-professor-profile"
                    >
                        <img src={getProfileImageURL(it.user_id)} onError={onProfileImageError} alt="profile image" />
                        <div className="card-professor-text">
                            <h4
                                onClick={() => navigate(`/profile/${it.user_id}`)}
                            >
                                {it.first_name} {it.last_name}
                            </h4>
                            <p>{it.about_me}</p>
                            {gMyId !== it.user_id &&
                                <button
                                    className="btn btn-slim btn-uppercase-sm"
                                    onClick={() => {
                                        const params = queryString.stringify({
                                            userId: it.user_id,
                                            firstname: it.first_name,
                                            lastname: it.last_name,
                                        })
                                        navigate(`/messages/?${params}`)
                                    }}
                                >
                                    Send Message
                                </button>
                            }
                        </div>
                    </div>
                })}
            </div>
        </Container>
    }
}

export const SubjectPrivateClasses = () => {
    const { subjectId } = useParams()

    let content
    if (subjectId == null) {
        content = <h3>Unknown subject ID</h3>
    } else {
        content = <SubjectPrivateClassesX subjectId={subjectId} />
    }

    return content
}

