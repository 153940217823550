import React from "react"
import { queryServer } from "../utils/queryServer"
import { useNavigate } from 'react-router-dom'
import { RequestResponseTypes } from "../utils/Common/RequestResponseTypes"
import { Container } from "../utils/Container"

export const PrivateClasses = () => {
    const [data, setData] = React.useState<RequestResponseTypes['getSubjectPrivateClasses']['response']>()

    const navigate = useNavigate()

    const fetchData = async () => {
        const response = await queryServer(
            'getSubjectPrivateClasses',
            undefined,
        )

        setData(response.sort((a, b) => a.subjectOrderNumber - b.subjectOrderNumber))
    }

    React.useEffect(() => {
        fetchData()
    }, [])

    let content
    if (data == null) {
        content = <h5>Please wait</h5>
    } else if (data.length === 0) {
        content = <h5>Still no private classes</h5>
    } else {
        content = <div>
            {data.map(subject => {
                return <div
                    key={subject.subjectId}
                    onClick={() => navigate(`/privateClasses/${subject.subjectId}`)}
                >
                    <h2 className="h-btn">
                        {subject.subjectInLocal}
                    </h2>
                </div>
            })}
        </div>
    }

    return <Container
        title="Subjects authority"
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
    >
        {content}
    </Container>
}
