import React from 'react'
import { AppContext } from '../contexts/AppContext'
import { check, checkNotNull } from "../utils/utilFunctions"
import { ChatDoc, ChatDoc_LastMessage } from '../utils/Common/Common'
import { collectionRef } from '../firestore'
import * as _ from "lodash"
import { DocumentData, getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter, where } from 'firebase/firestore'
import { onSnapshot } from 'firebase/firestore'

export type ChatDocWithDefinedLastMessage = ChatDoc & {
    lastMessage: ChatDoc_LastMessage
}

export const useLastNChats = () => {
    const LIMIT = 10

    const { gMyId } = React.useContext(AppContext)

    const [chats, setChats] = React.useState<{
        chats: ChatDocWithDefinedLastMessage[]
        // lastSeenSnapshot bi trebalo da postane null u trenutku kada ne bude bilo vise
        // chats za uzeti
        lastSeenSnapshot: QueryDocumentSnapshot<ChatDoc, DocumentData> | null
    }>()

    // lastNChatsListener will be used just for the last n chats. The rest of chats will be read
    // with query, clasic pagination (directly to firestore probably)
    const lastNChatsListener = () => {
        const myCollectionRef = collectionRef<ChatDocWithDefinedLastMessage>(`Chat`)
        const myQuery = query(
            myCollectionRef,
            where('availableForMembers', 'array-contains', gMyId),
            // result should have lastMessage != null, because orderBy also acts like a filter
            orderBy('lastMessage.timeSent', 'desc'),
            limit(LIMIT),
        )

        const unsubscribe = onSnapshot<ChatDocWithDefinedLastMessage, DocumentData>(
            myQuery,
            (querySnapshot) => {
                // since orderBy (.orderBy('lastMessage.timeSent', 'desc')) also acts as a
                // filter (it ignores items with null values), the lastMessage should exist
                // (eventualno ako ga ne zezne nesto sto je polje nullable, pa da baci error neki, mada
                // ne bi trebalo. videces kasnije ako zeza)
                const docs = querySnapshot.docs

                const chats = docs
                    .map(it => it.data())
                    .sort((a, b) => {
                        // check just to be sure
                        check(a.lastMessage != null && b.lastMessage != null, 'tkvSSxxcdss')
                        return b.lastMessage.timeSent - a.lastMessage.timeSent
                    })

                setChats({
                    chats,
                    // stavi uslov za < limit, jer ako je manje od limita onda sigurno nema vise chats
                    // (6.may2023) ee, ovo moze da bude buggy !!!!!!!!!! proveri! Jer kada npr ucitavas jos chats preko scrolling,
                    // i onda ti u medjuvremenu stigne neki novi chat, onda ce lastSeenSnapshot da bude poslednji od tih 20ak koji
                    // su novi "stigli". A ne bi trebalo, jer si vec ucitao neke starije chatove preko skrolovanja!!!!!!
                    // dakle, mora da se i cuva timestamp nekako od chata koji je u lastSeenSnapshot !!!!!!!!
                    // slicna fora je i kod messages. mada to cu da probam da odradim kako treba sad, ali vrv necu dobro, jer
                    // cu da odradim cisto da radi sto brze
                    lastSeenSnapshot: chats.length < LIMIT ? null : _.minBy(docs, (it) => it.data().lastMessage.timeSent) ?? null,
                })

                console.log('Last n chats listener is active...')
            },
            (err) => {
                console.log('Error jhTvvbn')
                console.log(err)
            },
        )

        return unsubscribe
    }

    React.useEffect(() => {
        const unsubscribe = lastNChatsListener()

        return () => {
            console.log('Remove listener for LAST N CHATS.')
            unsubscribe()
        }
    }, [])

    const readMoreChatsHelper = async (lastSeenSnapshot: QueryDocumentSnapshot<ChatDoc, DocumentData>) => {
        const myCollectionRef = collectionRef<ChatDocWithDefinedLastMessage>(`Chat`)
        const myQuery = query(
            myCollectionRef,
            where('availableForMembers', 'array-contains', gMyId),
            // result should have lastMessage != null, because orderBy also acts like a filter
            orderBy('lastMessage.timeSent', 'desc'),
            startAfter(lastSeenSnapshot),
            limit(LIMIT),
        )

        const snapshot = await getDocs(myQuery)

        const docs = snapshot.docs

        const arrivedChats = docs
            .map(it => {
                const chatDoc = it.data()
                // check just to be sure
                check(chatDoc.lastMessage != null, 'IOP9c9i')
                return chatDoc
            })

        setChats(prev => {
            check(prev != null, 's8ajhkdjkfdasd6jhg')

            const newChats = prev.chats
                .concat(arrivedChats)
                .sort((a, b) => b.lastMessage.timeSent - a.lastMessage.timeSent)

            return {
                chats: newChats,
                lastSeenSnapshot: _.minBy(docs, (it) => it.data().lastMessage.timeSent) ?? null,
            }
        })
    }

    return {
        chats,
        readMoreChats: chats?.lastSeenSnapshot == null ? null : async () => {
            await readMoreChatsHelper(checkNotNull(chats.lastSeenSnapshot, 'bcnmzcxz677'))
        }
    }
}