import React from 'react'
import { AppContext } from '../contexts/AppContext'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { queryServer } from './queryServer'

export const useUpdateLastCheck = () => {
    const {
        // notification
        gSetLastNotificationsCheck,
        gLastNotificationsCheck,
        gSetNotification,
        // messages
        gLastMessagesCheck,
        gSetLastMessagesCheck,
        gSetMessageNotification,
        // all comments
        gSetAllCommentsNotification,
        gLastAllComentsCheck,
        gSetLastAllComentsCheck,
    } = React.useContext(AppContext)

    const updateLastCheckHelper = async (
        fieldToUpdate: RequestResponseTypes['updateLastCheck']['request']['fieldToUpdate'],
        timestamp: number,
    ) => {
        const response = await queryServer(
            'updateLastCheck',
            {
                fieldToUpdate,
                timestamp,
            },
        )

        return response
    }

    const updateLastNotificationsCheck = async (timestamp: number) => {
        // not sure if gSetNotification is OK to do first. Check the order of these actions !!!!!! and whole logic everywhere in this file
        gSetNotification(false)
        const newTs = await updateLastCheckHelper('notifications', timestamp)
        gSetLastNotificationsCheck(Math.max(newTs, gLastNotificationsCheck))
    }

    const updateLastMessagesCheck = async (timestamp: number) => {
        gSetMessageNotification(false)
        const newTs = await updateLastCheckHelper('messages', timestamp)
        gSetLastMessagesCheck(Math.max(newTs, gLastMessagesCheck))
    }

    const updateLastAllCommentsCheck = async (timestamp: number) => {
        gSetAllCommentsNotification(false)
        const newTs = await updateLastCheckHelper('all_comments_check', timestamp)
        gSetLastAllComentsCheck(Math.max(newTs, gLastAllComentsCheck))
    }

    return {
        updateLastNotificationsCheck,
        updateLastMessagesCheck,
        updateLastAllCommentsCheck,
    }
}