import React from 'react'
import { Container } from "../utils/Container"
import { collectionRef } from '../firestore'
import { AppContext } from '../contexts/AppContext'
import { useUpdateLastCheck } from '../utils/useUpdateLastCheck'
import { assertNever, formatTimeFull, nowSecondsUTC } from '../utils/utilFunctions'
import { CommentDoc, TopCommentOnDetails } from '../utils/Common/Common'
import * as _ from "lodash"
import { renderStringWithLatex } from './LatexPart'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { DocumentData, getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter, where } from 'firebase/firestore'

export const AllCommentsCheckPage = () => {
    const { gLeadModeratorForSubjects, gStatus, gLastAllComentsCheck } = React.useContext(AppContext)
    const gLastAllComentsCheckMemoized = React.useMemo(() => {
        return gLastAllComentsCheck
    }, [])

    const { updateLastAllCommentsCheck } = useUpdateLastCheck()

    const [comments, setComments] = React.useState<{
        comments: CommentDoc[]
        lastSeenSnapshot: QueryDocumentSnapshot<CommentDoc, DocumentData> | null
    }>()

    React.useEffect(() => {
        updateLastAllCommentsCheck(nowSecondsUTC())
    }, [])

    // read directely from firestore. Avoid backend call
    const getAllComments = async () => {
        if (gStatus !== 'leadModerator') {
            return null
        }

        if (gLeadModeratorForSubjects.length === 0 || gLeadModeratorForSubjects.length > 10) {
            console.log('[SILENT ERROR oiuxzc789]::: bad gLeadModeratorForSubjects ::: ', gLeadModeratorForSubjects)
            return null
        }

        if (comments != null && comments.lastSeenSnapshot == null) {
            // ovo je glup uslov i nemam pojma da li je tacan. proveri kasnije nekad !!!!!!
            console.log('mozda nema vise komentara. dsarvvvz')
            return null
        }

        let readData: {
            comments: CommentDoc[],
            lastSeenSnapshot: QueryDocumentSnapshot<CommentDoc, DocumentData> | null,
        }

        if (comments?.lastSeenSnapshot == null) {
            const myCollectionRef = collectionRef<CommentDoc>("Comment")
            const myQuery = query(
                myCollectionRef,
                where('comment_on_subject_id', 'in', gLeadModeratorForSubjects),
                orderBy('last_updated_ts', 'desc'),
                limit(20),
            )
            const snapshot = await getDocs(myQuery)

            const docs = snapshot.docs

            readData = {
                comments: docs.map(doc => doc.data()),
                lastSeenSnapshot: _.minBy(docs, (it) => it.data().last_updated_ts) ?? null,
            }
        } else {
            const myCollectionRef = collectionRef<CommentDoc>("Comment")
            const myQuery = query(
                myCollectionRef,
                where('comment_on_subject_id', 'in', gLeadModeratorForSubjects),
                orderBy('last_updated_ts', 'desc'),
                startAfter(comments.lastSeenSnapshot),
                limit(20),
            )
            const snapshot = await getDocs(myQuery)

            const docs = snapshot.docs

            readData = {
                comments: docs.map(doc => doc.data()),
                lastSeenSnapshot: _.minBy(docs, (it) => it.data().last_updated_ts) ?? null,
            }
        }

        setComments(prev => ({
            comments: (prev?.comments ?? []).concat(readData.comments).sort((a, b) => b.last_updated_ts - a.last_updated_ts),
            lastSeenSnapshot: readData.lastSeenSnapshot,
        }))
    }

    const navigate = useNavigate()

    React.useEffect(() => {
        getAllComments()
    }, [])

    const commentIsOn = (comment: CommentDoc): string => {
        const on = comment.comment_on_details.on
        if (on === 'exercise_part') {
            return 'exercise text part'
        } else if (on === 'theory_part') {
            return 'theory text part'
        } else if (on === 'comment') {
            return 'comment'
        } else if (on === 'exercise_video') {
            return 'exercise video'
        } else if (on === 'theory_video') {
            return 'theory video'
        } else {
            assertNever(on)
        }
    }

    const commClickHandler_helper = (commentId: string, topCommentOnDetails: TopCommentOnDetails) => {
        const queryStringStringified = queryString.stringify({
            openCommentId: commentId,
        })

        const on = topCommentOnDetails.on

        if (on === 'exercise_part' || on === 'exercise_video') {
            navigate(`/exercise/${getEotId_helper(topCommentOnDetails)}?${queryStringStringified}`)
        } else if (on === 'theory_part' || on === 'theory_video') {
            navigate(`/theory/${getEotId_helper(topCommentOnDetails)}?${queryStringStringified}`)
        } else {
            assertNever(on)
        }
    }

    const commClickHandler = (comment: CommentDoc) => {
        const on = comment.comment_on_details.on

        if (
            on === 'exercise_part' ||
            on === 'exercise_video' ||
            on === 'theory_part' ||
            on === 'theory_video'
        ) {
            commClickHandler_helper(comment.id, comment.comment_on_details)
        } else if (on === 'comment') {
            commClickHandler_helper(comment.id, comment.comment_on_details.replyInCommentDetails)
        } else {
            assertNever(on)
        }
    }

    let content
    if (comments == null) {
        content = <div>
            Please wait...
        </div>
    } else if (comments.comments.length === 0) {
        content = <div>
            No comments yet
        </div>
    } else {
        content = <div>
            {comments.comments.map(comment => {
                let className = "comment-in-check-page"
                if (gLastAllComentsCheckMemoized < comment.last_updated_ts) {
                    className += " new_notification"
                }

                return <div
                    key={comment.id}
                    className={className}
                    onClick={() => commClickHandler(comment)}
                >
                    <div>
                        <span className="comment-info">Comment time: {formatTimeFull(comment.last_updated_ts)}</span>
                        <span className="comment-info">Comment author: {comment.authorFirstname} {comment.authorLastname}</span>
                    </div>
                    <div>
                        <span className="comment-info"> Comment is on ${commentIsOn(comment)}:</span>
                        <div>
                            {renderStringWithLatex(comment.comment_on_preview, 'black')}
                        </div>
                    </div>
                    <div>
                        <span className="comment-info"> Comment:</span>
                        <div>
                            {renderStringWithLatex(comment.content, 'black')}
                        </div>
                    </div>
                </div>
            })}
            {comments.lastSeenSnapshot != null && <span
                onClick={getAllComments}
                className='span-btn'
            >
                Read more comments
            </span>}
        </div>
    }

    return <Container
        title='All comments check page'
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
        className="contributors-exercise-page"
    >
        {content}
    </Container>
}

const getEotId_helper = (topCommentOnDetails: TopCommentOnDetails): string => {
    if (topCommentOnDetails.on === 'exercise_part' || topCommentOnDetails.on === 'exercise_video') {
        return topCommentOnDetails.exerciseId
    } else if (topCommentOnDetails.on === 'theory_part' || topCommentOnDetails.on === 'theory_video') {
        return topCommentOnDetails.theoryId
    } else {
        assertNever(topCommentOnDetails)
    }
}

// const getEotId = (commentOnDetails: CommentOnDetails): string => {
//     if (
//         commentOnDetails.on === 'exercise_part' ||
//         commentOnDetails.on === 'exercise_video' ||
//         commentOnDetails.on === 'theory_part' ||
//         commentOnDetails.on === 'theory_video'
//     ) {
//         return getEotId_helper(commentOnDetails)
//     } else if (commentOnDetails.on === 'comment') {
//         return getEotId_helper(commentOnDetails.replyInCommentDetails)
//     } else {
//         assertNever(commentOnDetails)
//     }
// }