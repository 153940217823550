import React from 'react'
import { AppContext } from '../contexts/AppContext'
import { Link } from "react-router-dom"
import defaultProfilePicture from '../utils/defaultProfilePicture.svg'
import { ProfileImage } from "../utils/ProfileImage"
import { TOKEN_NAME } from '../utils/queryServer'
import { NotificationsIcon } from '../Notifications/NotificationsIcon'
import queryString from 'query-string'
import { useModal } from '../utils/useModal'
import { ChangePasswordModal } from './ChangePasswordModal'
import classNames from "classnames"
import { ENVIRONMENT_CALCULATED } from '../utils/constants'
import { useClickOutside } from '../utils/useClickOutside'
import { useLocalLanguage } from '../utils/useLocalLanguage'

// check image logic !!!!!
// based on https://stackoverflow.com/questions/34097560/react-js-replace-img-src-onerror/48222599#48222599
export function onProfileImageError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    const target = e.target as HTMLImageElement
    target.onerror = null
    target.src = defaultProfilePicture
    return true
}

export function getProfileImageURL(userId: string): string {
    return `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_project_id}.appspot.com/o/${userId}%2FprofilePicture%2F${userId}-profile-picture?alt=media&token=x`
}

let home_page_is_rendered = false
export const updateHomePageIsRendered = (isRendered: boolean) => {
    home_page_is_rendered = isRendered
}

export function Header() {
    const {
        gMyId,
        gMessageNotification,
        gStatus,
        gAllCommentsNotification,
        gCountryId,
        gHigherAuthorityLevel,
    } = React.useContext(AppContext)

    return <div className="custom-header-wrapper">
        <div className='container'>
            <div className='custom-header'>
                <div className="custom-header-left">
                    <Link to="/home" onClick={() => {
                        if (home_page_is_rendered) {
                            location.reload()
                        }
                    }}>
                        <h2 className="slim-logo logo-font" style={{ marginRight: 0 }}>
                            Lakoje
                        </h2>
                    </Link>
                </div>
                <div className="custom-header-right">
                    {gStatus === 'leadModerator' && ENVIRONMENT_CALCULATED === "dev" && <div>
                        <span
                            className="mg-10"
                            aria-expanded="true"
                            style={{
                                color: gCountryId === 'eng-dev' || gCountryId === 'srb-dev' ? "green" : "red",
                            }}
                        >
                            {gCountryId !== 'eng-dev' && gCountryId !== 'srb-dev' ? "NOT" : ""} safe to solve as contributor
                        </span>
                    </div>}
                    {gStatus === 'leadModerator' && gHigherAuthorityLevel === 10 && <div>
                        <Link to='/scan-exercise'>
                            <span className="header-notification-text" aria-expanded="true">
                                Scan exercise
                            </span>
                        </Link>
                    </div>}
                    {(gStatus === 'leadModerator') && <div>
                        <Link to="/contribute">
                            <span className="header-notification-text right-separator" aria-expanded="true">
                                Contribute
                                {gAllCommentsNotification && <span className="indicator red"></span>}
                            </span>
                        </Link>
                    </div>}
                    {ENVIRONMENT_CALCULATED === "dev" && <div>
                        <Link to="/privateClasses">
                            <span className="header-notification-text" aria-expanded="true">
                                Private classes
                            </span>
                        </Link>
                    </div>}
                    {ENVIRONMENT_CALCULATED === "dev" && <div>
                        <Link to="/messages">
                            <span className="header-notification" aria-expanded="false">
                                <i className="icon ion-ios-chatboxes-outline"></i>
                                {gMessageNotification && <span className="indicator red"></span>}
                            </span>
                        </Link>
                    </div>}
                    <div>
                        <Link to={`/savedCollections?${queryString.stringify({ userId: gMyId })}`}>
                            <span className="header-notification" aria-expanded="false">
                                <i className="icon ion-heart"></i>
                            </span>
                        </Link>
                    </div>
                    {ENVIRONMENT_CALCULATED === "dev" && <NotificationsIcon />}
                    <NameAndProfileIcon />
                </div>
            </div>
        </div>
    </div>
}

const NameAndProfileIcon = () => {
    const [openDropdown, setOpenDropdown] = React.useState(false)

    const { gFirstname, gMyId } = React.useContext(AppContext)

    const modal = useModal(ChangePasswordModal)

    const ref = React.useRef<HTMLDivElement>(null)

    useClickOutside(ref, () => setOpenDropdown(false))

    const { inLocalLanguage } = useLocalLanguage()

    return <>
        <div ref={ref} className="dropdown dropdown-c">
            <span
                className={classNames(
                    "logged-user",
                    "hover-pointer",
                    "mg-l-7",
                )}
                onClick={() => setOpenDropdown(!openDropdown)}
            >
                <ProfileImage userId={gMyId} />
                <span
                    className='no-highlightable-text'
                    style={{ margin: "0 3px 0 3px" }}
                >
                    {gFirstname}
                </span>
                <i className="fa fa-angle-down"></i>
            </span>
            {openDropdown && <div className="dropdown-menu dropdown-menu-right">
                <nav className="nav" onClick={() => setOpenDropdown(false)}>
                    {ENVIRONMENT_CALCULATED === "dev" && <Link to={`/profile/${gMyId}`} className="nav-link">
                        <i className="icon ion-ios-person"></i> My profile
                    </Link>}
                    <span className="nav-link hover-pointer primary-color" onClick={() => modal.show({})}>
                        <i className="icon ion-ios-gear"></i> {inLocalLanguage('Change password')}
                    </span>
                    <span className="nav-link hover-pointer primary-color" onClick={() => {
                        localStorage.removeItem(TOKEN_NAME)
                        location.reload()
                    }}>
                        <i className="icon ion-forward"></i> {inLocalLanguage('Logout')}
                    </span>
                </nav>
            </div>}
        </div>
        {modal.render()}
    </>
}
