import { nanoid } from 'nanoid'
import React from 'react'
import {
    FormGroup,
    Label,
    Input,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from 'reactstrap'
import { useAllowedIsbns } from '../FindExercisePage/useAllowedIsbns'
import { IsbnParsed } from '../utils/Common/Common'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { queryServer } from '../utils/queryServer'
import { assertNever, check, checkNotNull } from '../utils/utilFunctions'
import * as _ from "lodash"

type Props = {
    gradesForSubject: RequestResponseTypes["getGradesForSubject"]["response"]
}

type SelectedIsbnAndAreas = {
    selectedIsbn: IsbnParsed
    // alreadySelectedIsbnAreas are selected areas from the "selectedIsbn" that are selected to be pushed in "pushInAreaId"
    alreadySelectedIsbnAreas: string[]
    // pushInAreaId is grade's area id that is prepared for receiving (pushing in) selected isbn's areas
    pushInAreaId: string | undefined
}

type OrganizationWithStatuses = {
    areaId: string
    order_number: number
    deep_level: number
    parent_id: string | null
    area_name: string
    status: "shouldSave" | "saved" | "shouldDelete"
    isbnAreasAssigned: {
        isbnAreaId: string
        isbnAreaTitle: string
        isbn: number
        status: "shouldSave" | "saved" | "shouldDelete"
    }[]
}[]

export const SubjectAuthority_Exercise = ({ gradesForSubject }: Props) => {
    const isbns = useAllowedIsbns()

    const [selectedIsbnAndAreas, setSelectedIsbnAndAreas] = React.useState<SelectedIsbnAndAreas>()
    const [selectedGrade, setSelectedGrade] = React.useState<{ id: string, grade_in_local: string }>()

    // areaOrganization will hold the data about what needs to be done (delete/create)
    const [areaOrganization, setAreaOrganization] = React.useState<OrganizationWithStatuses>()

    // calcAreaOrganization will only parse GetAreaOrganizationForGrade_Exercise__Response into
    // format we need to have here in order to be able to organize areas
    const calcAreaOrganizationAndSetState = (organization: RequestResponseTypes["getAreaOrganizationForGrade_Exercise"]["response"], isbns: IsbnParsed[]) => {
        const isbnAreaDetails: Map<string, {
            isbnAreaId: string
            isbnAreaTitle: string
            isbn: number
            status: "saved"
        }> = new Map(isbns.flatMap(isbn => isbn.areas.map(isbnArea => [isbnArea.id, {
            isbnAreaId: isbnArea.id,
            isbnAreaTitle: isbnArea.title,
            isbn: isbn.isbn,
            status: 'saved',
        }])))

        setAreaOrganization(organization.data.map(it => ({
            ...it,
            status: 'saved',
            isbnAreasAssigned: it.isbnAreasAssigned.map(assignedIsbnAreaId => checkNotNull(isbnAreaDetails.get(assignedIsbnAreaId), 'Vvbg7YtT'))
        })))
    }

    const getAreaOrganizationForGrade = async (gradeId: string, isbns: IsbnParsed[]) => {
        const organization = await queryServer(
            'getAreaOrganizationForGrade_Exercise',
            { gradeId },
        )

        calcAreaOrganizationAndSetState(organization, isbns)
    }

    const saveAreaOrganizationForGrade_Exercise = async (areaOrganization: OrganizationWithStatuses) => {
        const areaOrganization_requestParameter: RequestResponseTypes["saveAreaOrganizationForGrade_Exercise"]["request"]['areaOrganization'] = []

        areaOrganization.forEach(area => {
            const isbnAreasAssigned: {
                isbnAreaId: string
                todo: "save" | "delete"
            }[] = []

            area.isbnAreasAssigned.forEach(assignedIsbnArea => {
                if (assignedIsbnArea.status === 'shouldDelete') {
                    isbnAreasAssigned.push({
                        isbnAreaId: assignedIsbnArea.isbnAreaId,
                        todo: 'delete',
                    })
                } else if (assignedIsbnArea.status === 'shouldSave') {
                    isbnAreasAssigned.push({
                        isbnAreaId: assignedIsbnArea.isbnAreaId,
                        todo: 'save',
                    })
                }
            })

            if (area.status === 'saved') {
                if (isbnAreasAssigned.length > 0) {
                    areaOrganization_requestParameter.push({
                        areaId: area.areaId,
                        order_number: area.order_number,
                        deep_level: area.deep_level,
                        parent_id: area.parent_id,
                        area_name: area.area_name,
                        todo: "handleAssignedIsbnAreas",
                        isbnAreasAssigned,
                    })
                }
            } else if (area.status === 'shouldDelete') {
                areaOrganization_requestParameter.push({
                    areaId: area.areaId,
                    order_number: area.order_number,
                    deep_level: area.deep_level,
                    parent_id: area.parent_id,
                    area_name: area.area_name,
                    todo: 'delete',
                    isbnAreasAssigned,
                })
            } else if (area.status === 'shouldSave') {
                areaOrganization_requestParameter.push({
                    areaId: area.areaId,
                    order_number: area.order_number,
                    deep_level: area.deep_level,
                    parent_id: area.parent_id,
                    area_name: area.area_name,
                    todo: 'save',
                    isbnAreasAssigned,
                })
            } else {
                assertNever(area.status)
            }
        })

        const organization = await queryServer(
            'saveAreaOrganizationForGrade_Exercise',
            {
                gradeId: checkNotNull(selectedGrade, 'BuJksdi8I').id,
                areaOrganization: areaOrganization_requestParameter,
            },
        )

        calcAreaOrganizationAndSetState(organization, checkNotNull(isbns, 'Jkjsy6tsa554'))
        location.reload()
    }

    React.useEffect(() => {
        setForm_addNewAreaInOrganization(undefined)
        if (selectedGrade != null && isbns != null) {
            getAreaOrganizationForGrade(selectedGrade.id, isbns)
        }
    }, [selectedGrade, isbns])

    const [form_addNewAreaInOrganization, setForm_addNewAreaInOrganization] = React.useState<{
        addAreaIn: string,
        grade_area_order_number: number
        grade_area_deep_level: number
        grade_area_parent_id: string | null
        grade_area_area_name: string
    }>()

    // this pushes selected isbn areas in selected area from "skeleton"
    const pushSelectedIsbnAreas = () => {
        check(selectedIsbnAndAreas?.pushInAreaId != null &&
            areaOrganization != null,
            'ujkdU8iI87u'
        )

        const newAssignedIsbnAreasForSelectedGradeArea = [...areaOrganization.find(it => it.areaId === selectedIsbnAndAreas.pushInAreaId)!.isbnAreasAssigned]

        selectedIsbnAndAreas.alreadySelectedIsbnAreas.forEach(isbnAreaIdToAdd => {
            const isbnAlreadyAssigned = newAssignedIsbnAreasForSelectedGradeArea.find(it => it.isbnAreaId === isbnAreaIdToAdd) != null
            if (!isbnAlreadyAssigned) {
                newAssignedIsbnAreasForSelectedGradeArea.push({
                    status: 'shouldSave',
                    isbn: selectedIsbnAndAreas.selectedIsbn.isbn,
                    isbnAreaId: isbnAreaIdToAdd,
                    isbnAreaTitle: selectedIsbnAndAreas.selectedIsbn.areas.find(it => it.id === isbnAreaIdToAdd)!.title,
                })
            }
        })

        setAreaOrganization(prev => prev!.map(it => {
            if (it.areaId === selectedIsbnAndAreas.pushInAreaId) {
                return {
                    ...it,
                    isbnAreasAssigned: newAssignedIsbnAreasForSelectedGradeArea,
                }
            } else {
                return it
            }
        }))

        setSelectedIsbnAndAreas({
            ...selectedIsbnAndAreas,
            alreadySelectedIsbnAreas: [],
            pushInAreaId: undefined,
        })
    }

    const renderIsbnAreas = () => {
        if (selectedIsbnAndAreas == null) {
            return undefined
        } else {
            return <div>
                <div className="rendered-isbn-organization">
                    {_.sortBy(selectedIsbnAndAreas.selectedIsbn.areas, (it) => it.orderNum)
                        .map(isbnArea => <div key={isbnArea.id} style={{ marginLeft: `${isbnArea.deepLevel * 10 + 10}px` }}>
                            <span
                                onClick={() => {
                                    const isSelectedIsbnArea = selectedIsbnAndAreas.alreadySelectedIsbnAreas.find(areaId => areaId === isbnArea.id) != null
                                    const newSelectedIsbnAndAreas = isSelectedIsbnArea ? selectedIsbnAndAreas.alreadySelectedIsbnAreas.filter(areaId => areaId !== isbnArea.id) :
                                        selectedIsbnAndAreas.alreadySelectedIsbnAreas.concat(isbnArea.id)

                                    setSelectedIsbnAndAreas({
                                        ...selectedIsbnAndAreas,
                                        alreadySelectedIsbnAreas: newSelectedIsbnAndAreas,
                                    })
                                }}
                                style={{ color: selectedIsbnAndAreas.alreadySelectedIsbnAreas.find(areaId => areaId === isbnArea.id) ? 'green' : 'black' }}
                            >
                                {isbnArea.title}
                            </span>
                        </div>)}
                </div>
                <div style={{ margin: '20px', color: 'orange' }}>

                    <h6>
                        Selected isbn areas will
                        be pushed into: <b>{selectedIsbnAndAreas.pushInAreaId ? areaOrganization!.find(it => it.areaId === selectedIsbnAndAreas.pushInAreaId)!.area_name : 'NOT SELECTED'}</b>
                    </h6>
                </div>
                <div>
                    <Button
                        disabled={selectedIsbnAndAreas!.alreadySelectedIsbnAreas.length === 0 || selectedIsbnAndAreas!.pushInAreaId == null}
                        onClick={pushSelectedIsbnAreas}
                    >
                        Push-uj selektovane oblast
                    </Button>
                </div>
            </div>
        }
    }

    const renderAddNewAreaInOrganizationForm = () => {
        if (form_addNewAreaInOrganization != null) {
            return <div>
                <h4>Add area in {form_addNewAreaInOrganization.addAreaIn}</h4>
                <FormGroup>
                    <Label>Area name</Label>
                    <Input
                        type="text"
                        placeholder="area name..."
                        onChange={(e) => setForm_addNewAreaInOrganization({
                            ...form_addNewAreaInOrganization,
                            grade_area_area_name: e.target.value,
                        })}
                        value={form_addNewAreaInOrganization.grade_area_area_name}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Order number</Label>
                    <Input
                        type="number"
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        placeholder="order number..."
                        onChange={(e) => setForm_addNewAreaInOrganization({
                            ...form_addNewAreaInOrganization,
                            grade_area_order_number: +e.target.value,
                        })}
                        value={form_addNewAreaInOrganization.grade_area_order_number}
                    />
                </FormGroup>
                <Button onClick={() => {
                    setAreaOrganization(prev => {
                        if (
                            form_addNewAreaInOrganization.grade_area_area_name !== '' &&
                            form_addNewAreaInOrganization.grade_area_order_number > 0
                        ) {
                            if (prev != null) {
                                setForm_addNewAreaInOrganization(undefined)
                                return prev.concat({
                                    areaId: nanoid(),
                                    deep_level: form_addNewAreaInOrganization.grade_area_deep_level,
                                    order_number: form_addNewAreaInOrganization.grade_area_order_number,
                                    parent_id: form_addNewAreaInOrganization.grade_area_parent_id,
                                    area_name: form_addNewAreaInOrganization.grade_area_area_name,
                                    status: 'shouldSave',
                                    isbnAreasAssigned: [],
                                })
                            } else {
                                console.log('Strange behavior... check this HHHyyU7uj7WseP0Eat')
                                return prev
                            }
                        } else {
                            console.log('not good filled')
                            return prev
                        }
                    })
                }}>
                    Ok
                </Button>
                <Button onClick={() => setForm_addNewAreaInOrganization(undefined)}>
                    Cancel
                </Button>
            </div>
        } else {
            return undefined
        }
    }

    const deletePushedIsbnArea = (targetExerciseAreaId: string, targetIsbnAreaId: string) => {
        check(areaOrganization != null, 'o1bTyTndsu')
        const newOrganization: OrganizationWithStatuses = []

        areaOrganization.forEach(it => {
            if (it.areaId === targetExerciseAreaId) {
                const newAssignedIsbnAreas: {
                    isbnAreaId: string
                    isbnAreaTitle: string
                    isbn: number
                    status: "shouldSave" | "saved" | "shouldDelete"
                }[] = []

                it.isbnAreasAssigned.forEach(assigned => {
                    if (assigned.isbnAreaId === targetIsbnAreaId) {
                        if (assigned.status === 'saved') {
                            newAssignedIsbnAreas.push({
                                ...assigned,
                                status: 'shouldDelete',
                            })
                        } else if (assigned.status === 'shouldDelete') {
                            // it already is set to be deleted, so toggle (return to saved)
                            newAssignedIsbnAreas.push({
                                ...assigned,
                                status: 'saved',
                            })
                        } else if (assigned.status === 'shouldSave') {
                            // do nothing because it is not saved at all (so doing nothing is like deleting it acctually)
                        } else {
                            assertNever(assigned.status)
                        }
                    } else {
                        newAssignedIsbnAreas.push(assigned)
                    }
                })

                newOrganization.push({
                    ...it,
                    isbnAreasAssigned: newAssignedIsbnAreas,
                })
            } else {
                newOrganization.push(it)
            }
        })

        setAreaOrganization(newOrganization)
    }

    const deleteArea = (targetExerciseAreaId: string) => {
        check(areaOrganization != null, 'o1Yu7bndsu')
        const newOrganization: OrganizationWithStatuses = []

        areaOrganization.forEach(it => {
            if (it.areaId === targetExerciseAreaId) {
                // ovo nije delete dugme, vec toggle dugme. tako da ova provera nije dobra. Imas u subjectAuthority_theory
                // objasnjenje bolje zasto nije ok (treba da bude "allowedToToggle")
                const allowedToDelete = it.isbnAreasAssigned.find(it => it.status === 'saved' || it.status === 'shouldSave') == null &&
                    areaOrganization.find(it => it.parent_id === targetExerciseAreaId && (it.status === 'saved' || it.status === 'shouldSave')) == null

                if (allowedToDelete) {
                    if (it.status === 'saved') {
                        newOrganization.push({
                            ...it,
                            status: 'shouldDelete',
                        })
                    } else if (it.status === 'shouldDelete') {
                        // it already is set to be deleted, so toggle (return to saved)
                        newOrganization.push({
                            ...it,
                            status: 'saved',
                        })
                    } else if (it.status === 'shouldSave') {
                        // do nothing because it is not saved at all (so doing nothing is like deleting it acctually)
                    } else {
                        assertNever(it.status)
                    }
                } else {
                    console.log('############# not allowed to delete. First empty assigned isbn areas, and all children')
                    newOrganization.push(it)
                }
            } else {
                newOrganization.push(it)
            }
        })

        setAreaOrganization(newOrganization)
    }

    if (isbns == null) {
        return <h2>please wait...</h2>
    }

    const renderGradeOrganization = (organization: OrganizationWithStatuses) => {
        if (organization.length === 0) {
            return <h3>The grade has no organization yet</h3>
        } else {
            return <div>
                <div className="rendered-isbn-organization">
                    {_.sortBy(organization, (it) => it.order_number)
                        .map(area => {
                            let color
                            if (area.status === 'saved') {
                                color = 'black'
                            } else if (area.status === 'shouldDelete') {
                                color = 'red'
                            } else if (area.status === 'shouldSave') {
                                color = 'green'
                            } else {
                                color = 'yellow'
                            }

                            return <div key={area.areaId} style={{ marginLeft: `${area.deep_level * 10 + 10}px`, marginBottom: '15px' }}>
                                <div>
                                    <span
                                        onClick={() => console.log('nothingy767')}
                                        style={{ color }}
                                    >
                                        {area.area_name} <span style={{ color: "gray" }}>({area.order_number})</span>
                                    </span>
                                    <span
                                        className='span-btn'
                                        onClick={() => {
                                            setForm_addNewAreaInOrganization({
                                                addAreaIn: area.area_name,
                                                grade_area_order_number: -1,
                                                grade_area_deep_level: area.deep_level + 1,
                                                grade_area_parent_id: area.areaId,
                                                grade_area_area_name: '',
                                            })
                                        }}
                                    >
                                        Dodaj podoblast
                                    </span>
                                    <span
                                        className='span-btn'
                                        onClick={() => {
                                            setSelectedIsbnAndAreas(prev => {
                                                if (prev != null) {
                                                    return {
                                                        ...prev,
                                                        pushInAreaId: area.areaId,
                                                    }
                                                } else {
                                                    return prev
                                                }
                                            })
                                        }}
                                    >
                                        {!selectedIsbnAndAreas && `Select ISBN to be able to `} Select for isbn areas PUSH
                                    </span>
                                    <span
                                        className='span-btn'
                                        style={{ color: 'red' }}
                                        onClick={() => deleteArea(area.areaId)}
                                    >
                                        delete
                                    </span>
                                </div>
                                <div>
                                    {area.isbnAreasAssigned.map(pushedIsbn => {
                                        let color
                                        if (pushedIsbn.status === 'saved') {
                                            color = 'black'
                                        } else if (pushedIsbn.status === 'shouldDelete') {
                                            color = 'red'
                                        } else if (pushedIsbn.status === 'shouldSave') {
                                            color = 'green'
                                        } else {
                                            color = 'yellow'
                                        }
                                        return <span
                                            style={{ color, margin: '4px' }}
                                            key={pushedIsbn.isbnAreaId}
                                            onClick={() => deletePushedIsbnArea(area.areaId, pushedIsbn.isbnAreaId)}
                                        >
                                            {pushedIsbn.isbnAreaTitle} ({pushedIsbn.isbn});
                                        </span>
                                    })}
                                </div>
                            </div>
                        })}
                </div>
            </div>
        }
    }

    const renderRightBoxDetails = () => {
        if (areaOrganization == null) {
            return null
        } else {
            return <div>
                {renderAddNewAreaInOrganizationForm()}
                {renderGradeOrganization(areaOrganization)}
                {selectedGrade && <span
                    className='span-btn'
                    onClick={() => {
                        setForm_addNewAreaInOrganization({
                            addAreaIn: "Top-level",
                            grade_area_order_number: -1,
                            grade_area_deep_level: 0,
                            grade_area_parent_id: null,
                            grade_area_area_name: '',
                        })
                    }}
                >
                    Add top-level area
                </span>}
                <div>
                    <Button
                        onClick={() => saveAreaOrganizationForGrade_Exercise(areaOrganization)}
                    >
                        Save exercise areas organization!
                    </Button>
                </div>
            </div>
        }
    }

    return <div className='verticaly-boxes'>
        <div className='left-box'>
            <FormGroup>
                <Label for="difficulty">Choose isbn</Label>
                <UncontrolledDropdown id="difficulty">
                    <DropdownToggle caret outline>
                        {selectedIsbnAndAreas ? `${selectedIsbnAndAreas.selectedIsbn.bookTitle}, ${selectedIsbnAndAreas.selectedIsbn.isbn}` : "Choose isbn"}
                    </DropdownToggle>
                    <DropdownMenu>
                        {isbns.map((isbn, index) => <DropdownItem
                            key={index}
                            onClick={() => {
                                if (selectedIsbnAndAreas?.selectedIsbn.isbn !== isbn.isbn) {
                                    setSelectedIsbnAndAreas({
                                        selectedIsbn: isbn,
                                        alreadySelectedIsbnAreas: [],
                                        pushInAreaId: undefined,
                                    })
                                }
                            }}
                        >
                            {isbn.bookTitle}, {isbn.isbn}
                        </DropdownItem>)}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </FormGroup>
            {renderIsbnAreas()}
        </div>
        <div className='right-box'>
            <FormGroup>
                <Label for="difficulty">Chose grade</Label>
                <UncontrolledDropdown id="difficulty">
                    <DropdownToggle caret outline>
                        {selectedGrade ? `${selectedGrade.grade_in_local}` : "Chose the grade"}
                    </DropdownToggle>
                    <DropdownMenu>
                        {_.sortBy(gradesForSubject.grades, (it) => it.order_number)
                            .map((grade, index) => <DropdownItem
                                key={index}
                                onClick={() => {
                                    setForm_addNewAreaInOrganization(undefined)
                                    setSelectedIsbnAndAreas(prev => {
                                        if (prev == null) {
                                            return prev
                                        } else {
                                            return {
                                                selectedIsbn: prev.selectedIsbn,
                                                alreadySelectedIsbnAreas: prev.alreadySelectedIsbnAreas,
                                                pushInAreaId: undefined,
                                            }
                                        }
                                    })
                                    setSelectedGrade(grade)
                                }}
                            >
                                {grade.grade_in_local}
                            </DropdownItem>)}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </FormGroup>
            {renderRightBoxDetails()}
        </div>
    </div>
}
