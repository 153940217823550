import React from 'react'
import { Container } from '../utils/Container'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap'
import classNames from "classnames"
import { MyExerciseList } from './MyExerciseList'
import { MyTheoryList } from './MyTheoriesList'

type MySolvingsTab = 'exercise' | 'theory' | 'exercise_2'

export const MySolvingsPage = () => {
    const [activeTab, setActiveTab] = React.useState<MySolvingsTab>('exercise')

    return <Container
        title="My solvings page"
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
    >
        <div className="wrapper-2">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classNames("tabb-x", { active: activeTab === 'exercise' })}
                        onClick={() => setActiveTab('exercise')}
                    >
                        Exercises
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classNames("tabb-x", { active: activeTab === 'theory' })}
                        onClick={() => setActiveTab('theory')}
                    >
                        Theory
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="exercise">
                    <Row>
                        <Col sm="12">
                            <MyExerciseList />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="theory">
                    <Row>
                        <Col sm="12">
                            <MyTheoryList />
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    </Container>
}
