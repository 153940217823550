import { getSignedPutUrl } from "./getSignedPutUrl"
import { check, checkNotNull } from "./utilFunctions"

export async function uploadFile(
    file: File,
    bucketName: string,
): Promise<{ fileUrl: string, fileId: string } | null> {
    try {
        const signedPutUrl = checkNotNull(await getSignedPutUrl(bucketName, file.type), ">>> signedPutUrl == null <<<")

        const uploadFileResult = await fetch(signedPutUrl, {
            method: "PUT",
            headers: { "Content-Type": file.type },
            body: file,
        })

        check(uploadFileResult.ok, ">>> uploadFileResult.ok <<<")

        const fileUrl = uploadFileResult.url.split("?")[0]
        const fileId = fileUrl.split("/").slice(-1)[0]

        return { fileUrl, fileId }
    } catch (e) {
        console.log(e)
        return null
    }
}
