import React from 'react'
import { check } from "../utils/utilFunctions"
import { queryServer } from "../utils/queryServer"
import { QueriedComment, TopCommentOnDetails } from '../utils/Common/Common'

type Props = {
    topCommentOnDetails: TopCommentOnDetails
    topCommentId: string
    topCommentAuthor: {
        userId: string
        firstname: string
        lastname: string
    }
    topCommentContent: string
    topCommentTs: number
    hasReplies: boolean
    initialyQueriedCommentReplies: QueriedComment[] | undefined
    initialyLenderLoadNewerComments: boolean
    subjectId: string
}

export const CommentWithReplies = ({
    topCommentOnDetails,
    topCommentId,
    topCommentAuthor,
    topCommentContent,
    topCommentTs,
    hasReplies,
    initialyQueriedCommentReplies,
    initialyLenderLoadNewerComments,
    subjectId,
}: Props) => {
    const [commentText, setCommentText] = React.useState('')
    const [queriedComments, setQueriedComments] = React.useState<QueriedComment[] | undefined>(initialyQueriedCommentReplies)
    const [renderLoadNewerComments, setRenderLoadNewerComments] = React.useState(initialyLenderLoadNewerComments)

    const [hasMoreComments, setHasMoreComments] = React.useState(hasReplies)

    const [reply, setReply] = React.useState<{
        onCommentPreview: string,
        toUser: {
            userId: string
            firstname: string
            lastname: string
        }
        onCommentId: string
    }>()

    const getOlderComments = async (lastReadDocId: string | null) => {
        const comments = await queryServer(
            'getComments',
            {
                inId: topCommentId,
                lastReadDocId,
            },
        )

        // mod 3 because pagination is by 3 on backend. If change on backend, change here too !!!
        if (comments.comments.length > 0 && comments.comments.length % 3 === 0) {
            setHasMoreComments(true)
        } else {
            setHasMoreComments(false)
        }

        setQueriedComments(prev => (prev ?? []).concat(comments.comments).sort((a, b) => a.lastUpdatedTs - b.lastUpdatedTs))
    }

    const getNewerComments = async (lastSeenCommentId: string) => {
        const comments = await queryServer(
            'getNewerComments',
            {
                inId: topCommentId,
                lastSeenCommentId,
            },
        )

        // mod 3 because pagination is by 3 on backend. If change on backend, change here too !!! dsa786jgh
        if (comments.comments.length > 0 && comments.comments.length % 3 === 0) {
            setRenderLoadNewerComments(true)
        } else {
            setRenderLoadNewerComments(false)
        }

        setQueriedComments(prev => (prev ?? []).concat(comments.comments).sort((a, b) => a.lastUpdatedTs - b.lastUpdatedTs))
    }

    const setCommentReply = async () => {
        check(reply != null, 'dsa78YUIsqq')
        const result = await queryServer(
            'setComment',
            {
                content: commentText,
                comment_on_details: {
                    on: 'comment',
                    onId: reply.onCommentId,
                    replyInCommentDetails: topCommentOnDetails,
                    topCommentId: topCommentId,
                },
                comment_in_id: topCommentId,
                // for now, let the comment_on_subject_id be null for comment replies. Maybe will be changed later
                // (the reason is to avoid sending notifications to the lead moderators when someone set comment reply)
                // but maybe change this later (maybe I am 51% to show this)
                comment_on_subject_id: subjectId,
                comment_on_preview: 'should be reply.preview dsa987uyZX',
                response_to_user: reply.toUser.userId,
            },
        )

        setQueriedComments(prev => (prev ?? []).concat(result).sort((a, b) => a.lastUpdatedTs - b.lastUpdatedTs))
        setCommentText('')
    }

    const renderReplies = () => {
        let replies
        if (queriedComments == null) {
            replies = null
        } else if (queriedComments.length === 0) {
            replies = <span>No replies yet</span>
        } else {
            replies = <div className="">
                {queriedComments.map(comment => {
                    return <div key={comment.commentId}>
                        <div style={{ color: 'orange' }}>
                            {comment.content}
                        </div>
                        <span style={{ color: 'blue' }} onClick={() => {
                            setReply({
                                onCommentPreview: 'hardcoded, toFix dsa78VBN',
                                toUser: {
                                    userId: comment.authorId,
                                    firstname: comment.authorFirstname,
                                    lastname: comment.authorLastname,
                                },
                                onCommentId: comment.commentId,
                            })
                        }}>Reply</span>
                    </div>
                })}
            </div>
        }

        return replies
    }

    const renderLoadOlderReplies = () => {
        if (hasMoreComments) {
            if (queriedComments == null) {
                return <span style={{ color: 'purple' }} onClick={() => getOlderComments(null)}>
                    See replies
                </span>
            } else if (queriedComments.length === 0) {
                // this branch should not be possible!!!
                // fix this stupid logic !!!!!!!
                throw Error('9ZZdsadsaiouiouuio898789')
            } else {
                return <span style={{ color: 'purple' }} onClick={() => getOlderComments(queriedComments[0].commentId)}>
                    See older replies
                </span>
            }
        } else {
            return null
        }
    }

    const renderLoadNewerReplies = () => {
        if (renderLoadNewerComments) {
            return <span
                style={{ color: 'purple' }}
                onClick={() => {
                    if (queriedComments == null || queriedComments.length === 0) {
                        setRenderLoadNewerComments(false)
                    } else {
                        const newestCommentId = queriedComments.reduce((max, curr) => {
                            if (curr.lastUpdatedTs > max.lastUpdatedTs) {
                                return curr
                            } else {
                                return max
                            }
                        }, queriedComments[0]).commentId
                        getNewerComments(newestCommentId)
                    }
                }}
            >
                See newer replies
            </span>
        } else {
            return null
        }
    }

    const renderReplyInput = () => {
        if (reply == null) {
            return null
        } else {
            return <div>
                <div className="form-group">
                    <input
                        autoComplete="off"
                        type="text"
                        placeholder="Type a reply here..."
                        onChange={e => setCommentText(e.target.value)}
                        value={commentText}
                    />
                </div>
                <div>
                    <button
                        className="btn btn-purple"
                        onClick={setCommentReply}
                        disabled={commentText.length === 0}
                    >
                        OK
                    </button>
                    <button onClick={() => setReply(undefined)}>x</button>
                </div>
            </div>
        }
    }

    return <div>
        <div style={{ color: 'green' }}>
            {topCommentContent}
        </div>

        <div style={{ marginLeft: '20px' }}>
            <span onClick={() => {
                setReply({
                    onCommentPreview: 'hardcoded, toFix OOii9qwm',
                    toUser: {
                        userId: topCommentAuthor.userId,
                        firstname: topCommentAuthor.firstname,
                        lastname: topCommentAuthor.lastname,
                    },
                    onCommentId: topCommentId,
                })
            }}>
                Reply &nbsp;
            </span>
            {renderLoadOlderReplies()}
            {renderReplies()}
            {renderLoadNewerReplies()}
            {renderReplyInput()}
        </div>
    </div>
}