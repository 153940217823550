import React from 'react'
import {
    ExerciseInformationsForGrade,
    GradeBasics,
    SubjectWithGrades,
    TestInGrade,
    TheoryInformationsForGrade,
} from '../utils/Common/Common'
import { check } from '../utils/utilFunctions'
import { FormGroup, Label } from 'reactstrap'
import { queryServer } from '../utils/queryServer'
import { OrganizationForGrade } from './OrganizationForGrade'
import Select from 'react-select'
import * as _ from "lodash"
import { useLocalLanguage } from '../utils/useLocalLanguage'
import { LAST_FILTERS } from './LastFilters'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'

type Props = {
    subjectsAndGrades: SubjectWithGrades[]
    // if singleOptionData exists, then it means that there is one subject with one grade
    singleOptionData: {
        exercise: ExerciseInformationsForGrade
        theory: TheoryInformationsForGrade
        exerciseTests: TestInGrade[] | undefined
    } | undefined
}

export type ChosenSubjectAndGrade = {
    chosenSubject: SubjectWithGrades | null
    chosenGrade: GradeBasics | null
}

export const HomeData = ({ subjectsAndGrades, singleOptionData }: Props) => {
    const { inLocalLanguage } = useLocalLanguage()

    const checkItIsSingleOption = () => {
        check(
            subjectsAndGrades.length === 1
            && subjectsAndGrades[0].grades.length === 1
            && singleOptionData != null,
            'CVbn9Xg',
        )
    }

    const checkItIsMultipleOptions = () => {
        check(
            (subjectsAndGrades.length > 1 ||
                subjectsAndGrades.length === 1 && subjectsAndGrades[0].grades.length > 1)
            && singleOptionData == null,
            'DjXPnmYe6',
        )
    }

    const calcInitialChosenSubjectAndGrade = (): ChosenSubjectAndGrade => {
        if (singleOptionData != null) {
            checkItIsSingleOption()

            const chosenSubjectAndGrade = {
                chosenSubject: subjectsAndGrades[0]!,
                chosenGrade: subjectsAndGrades[0]!.grades[0]!,
            }

            LAST_FILTERS.subjectAndGrade = chosenSubjectAndGrade
            return chosenSubjectAndGrade
        } else {
            checkItIsMultipleOptions()

            if (subjectsAndGrades.length === 1) {
                const chosenSubject = subjectsAndGrades[0]!
                const chosenGrade = chosenSubject.grades
                    .find(grade => grade.gradeId === LAST_FILTERS.subjectAndGrade?.chosenGrade?.gradeId) ?? null

                const chosenSubjectAndGrade = {
                    chosenSubject,
                    chosenGrade,
                }

                LAST_FILTERS.subjectAndGrade = chosenSubjectAndGrade
                return chosenSubjectAndGrade
            } else {
                const chosenSubject = subjectsAndGrades
                    .find(subject => subject.subjectId === LAST_FILTERS.subjectAndGrade?.chosenSubject?.subjectId) ?? null
                const chosenGrade = chosenSubject?.grades
                    .find(grade => grade.gradeId === LAST_FILTERS.subjectAndGrade?.chosenGrade?.gradeId) ?? null

                const chosenSubjectAndGrade = {
                    chosenSubject,
                    chosenGrade,
                }

                LAST_FILTERS.subjectAndGrade = chosenSubjectAndGrade
                return chosenSubjectAndGrade
            }
        }
    }

    const subjectsAndGradesSortedAndMemoized = React.useMemo(() => {
        // there has to be 1+ subjects, and each subject needs to have 1+ grades
        check(subjectsAndGrades.length >= 1, 'iKh6Yc0E')
        subjectsAndGrades.forEach(it => check(it.grades.length >= 1, `yDDD546s ; subjectId: ${it.subjectId}`))

        return _.sortBy(subjectsAndGrades, (it) => it.orderNumber)
            .map(subjectWithGrades => ({
                ...subjectWithGrades,
                grades: _.sortBy(subjectWithGrades.grades, (it) => it.orderNumber)
            }))
    }, []) // I think there is no need to put subjectsAndGrades in deps, because it actually should not be changed

    // Use ref instead of state, because it is important to know what is last chosen gradeId in
    // getOrganizationForChosenGrade. This can be improved later, but for now let it be (3.1.2023.)
    const chosenSubjectAndGradeRef = React.useRef<ChosenSubjectAndGrade>(calcInitialChosenSubjectAndGrade())
    const [dummy_chosenSubjectAndGrade, setDummy_chosenSubjectAndGrade] = React.useState(0)
    const setChosenSubjectAndGrade = (data: ChosenSubjectAndGrade) => {
        LAST_FILTERS.allExercisesOrTests = "allExercises"
        // don't reset lastGeneratedExercisesTest for now. Only reset allExercisesOrTests
        // LAST_FILTERS.lastGeneratedExercisesTest = undefined
        LAST_FILTERS.subjectAndGrade = data
        chosenSubjectAndGradeRef.current = data
        setDummy_chosenSubjectAndGrade(prev => prev + 1)
    }

    const [organizationForChosenGrade, setOrganizationForChosenGrade] = React.useState<RequestResponseTypes["getOrganizationForGrade"]["response"] | undefined>(singleOptionData)

    const getOrganizationForChosenGrade = async (gradeId: string) => {
        // if there is singleOptionData, do not fetch for grade organization
        if (singleOptionData != null) {
            checkItIsSingleOption()
            // do nothing
        } else {
            checkItIsMultipleOptions()

            setOrganizationForChosenGrade(undefined)

            const data = await queryServer(
                'getOrganizationForGrade',
                { gradeId },
            )

            if (chosenSubjectAndGradeRef.current.chosenGrade?.gradeId === gradeId) {
                // set organization only if in meantime chosen grade was not changed
                setOrganizationForChosenGrade(data)
            }
        }
    }

    React.useEffect(() => {
        if (chosenSubjectAndGradeRef.current.chosenGrade != null) {
            getOrganizationForChosenGrade(chosenSubjectAndGradeRef.current.chosenGrade.gradeId)
        } else {
            setOrganizationForChosenGrade(undefined)
        }
    }, [dummy_chosenSubjectAndGrade])

    const renderSubjectOptions = () => {
        return <FormGroup>
            <Label style={{ marginBottom: "2px" }}>{inLocalLanguage('Subject')}</Label>
            <Select
                isSearchable={false}
                options={subjectsAndGradesSortedAndMemoized}
                value={chosenSubjectAndGradeRef.current.chosenSubject}
                getOptionLabel={(option) => option.inLocal}
                onChange={option => {
                    const chosenGrade = option != null && option.grades.length === 1 ? option.grades[0] : null
                    setChosenSubjectAndGrade({
                        chosenSubject: option ?? null,
                        chosenGrade,
                    })
                }}
                isOptionSelected={option => option.subjectId === chosenSubjectAndGradeRef.current.chosenSubject?.subjectId}
                placeholder={`${inLocalLanguage("Chose")} ${inLocalLanguage('Subject').toLowerCase()}`}
                styles={{
                    control: (base) => ({
                        ...base,
                        fontSize: "16px",
                    }),
                    option: (base) => ({
                        ...base,
                        fontSize: "16px",
                    }),
                }}
            />
        </FormGroup>
    }

    const renderGradesOptions = () => {
        if (chosenSubjectAndGradeRef.current.chosenSubject != null) {
            return <FormGroup>
                <Label style={{ marginBottom: "2px" }}>{inLocalLanguage('Level')}</Label>
                <Select
                    isSearchable={false}
                    options={chosenSubjectAndGradeRef.current.chosenSubject.grades}
                    value={chosenSubjectAndGradeRef.current.chosenGrade}
                    getOptionLabel={(option) => option.inLocal}
                    onChange={option => {
                        setChosenSubjectAndGrade({
                            chosenSubject: chosenSubjectAndGradeRef.current.chosenSubject,
                            chosenGrade: option ?? null,
                        })
                    }}
                    isOptionSelected={option => option.gradeId === chosenSubjectAndGradeRef.current.chosenGrade?.gradeId}
                    placeholder={`${inLocalLanguage("Chose")} ${inLocalLanguage('Level').toLowerCase()}`}
                    styles={{
                        control: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                        option: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                    }}
                />
            </FormGroup>
        } else {
            return null
        }
    }

    const renderOrganizationForGrade = () => {
        if (organizationForChosenGrade != null) {
            return <OrganizationForGrade
                exercise={organizationForChosenGrade.exercise}
                theory={organizationForChosenGrade.theory}
                exerciseTests={organizationForChosenGrade.exerciseTests}
            />
        } else {
            return null
        }
    }

    return (
        <div>
            {renderSubjectOptions()}
            {renderGradesOptions()}
            {renderOrganizationForGrade()}
        </div>
    )
}
