import { nanoid } from 'nanoid'
import React from 'react'
import { FormGroup, Input } from 'reactstrap'
import { EotPart } from '../utils/Common/Common'
import { uploadPhoto } from '../utils/uploadPhoto'
import { assertNever, check } from '../utils/utilFunctions'
import { EotPartAndRendered } from './SolveEot'

type Props = {
    togglePart: () => void,
    deletePart: () => void,
    eotPart: EotPart,
    setEotParts: React.Dispatch<React.SetStateAction<EotPartAndRendered[]>>
}

export const PlainTextPart = ({
    togglePart,
    deletePart,
    eotPart,
    setEotParts,
}: Props): JSX.Element => {
    const [orderNumber, setOrderNumber] = React.useState(eotPart.order_number)
    const [photoMode, setPhotoMode] = React.useState<'upload' | 'url'>('upload')

    let content
    if (eotPart.content.type === 'text') {
        content = <FormGroup>
            <div>
                <Input
                    type="number"
                    style={{ width: 300, display: 'inline-block' }}
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(+e.target.value)}
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                />
                <span
                    className='span-btn'
                    style={{ backgroundColor: orderNumber === eotPart.order_number ? 'white' : 'blue' }}
                    onClick={() => {
                        setEotParts(prev => prev.map(prevPart => {
                            if (prevPart.id === eotPart.id) {
                                return {
                                    ...prevPart,
                                    order_number: orderNumber,
                                }
                            } else {
                                return prevPart
                            }
                        }))
                    }}
                >
                    OK
                </span>
            </div>
            <Input
                bsSize='lg'
                className="solving-input"
                type="textarea"
                value={eotPart.content.text}
                onChange={(e) => {
                    setEotParts(prev => prev.map(prevPart => {
                        if (prevPart.id === eotPart.id) {
                            check(prevPart.content.type === 'text', 'uUyT7nBm')
                            return {
                                ...prevPart,
                                content: {
                                    type: 'text',
                                    text: e.target.value,
                                },
                            }
                        } else {
                            return prevPart
                        }
                    }))
                }}
            />
        </FormGroup>
    } else if (eotPart.content.type === 'picture') {
        content = <div>
            <div>
                <Input
                    type="number"
                    style={{ width: 300, display: 'inline-block' }}
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(+e.target.value)}
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                />
                <span
                    className='span-btn'
                    style={{ backgroundColor: orderNumber === eotPart.order_number ? 'white' : 'blue' }}
                    onClick={() => {
                        setEotParts(prev => prev.map(prevPart => {
                            if (prevPart.id === eotPart.id) {
                                return {
                                    ...prevPart,
                                    order_number: orderNumber,
                                }
                            } else {
                                return prevPart
                            }
                        }))
                    }}
                >
                    OK
                </span>
            </div>
            <div className='mg-b-20 mg-t-10'>
                [this part {eotPart.content.pictureUrl.length > 0 ? 'HAS' : "DOESN'T HAVE"} photo]
                {eotPart.content.pictureUrl.length > 0 && <img
                    src={eotPart.content.pictureUrl}
                    alt="No, or bad image..."
                    width="120px"
                />}
            </div>
            <div>
                <span style={{ color: "brown" }}>
                    {photoMode === 'upload' ? 'Mode: "Upload photo"' : 'Mode: "Paste URL"'}
                </span> <span className='span-btn' onClick={() => {
                    setPhotoMode(prev => prev === 'upload' ? "url" : "upload")
                }}>
                    Toggle mode
                </span>
            </div>
            {photoMode === 'upload' ? <input
                id="filePicker"
                style={{}}
                type={"file"}
                onChange={(e) => uploadPhoto(
                    e,
                    'exercise-solution-part-photo',
                    // later eotId can be added as part of photoId
                    // (in that case eotId should be calculated at he client)
                    nanoid(),
                    (imgUrl) => {
                        setEotParts(prev => prev.map(prevPart => {
                            if (prevPart.id === eotPart.id) {
                                check(prevPart.content.type === 'picture', 'po9OiJA')
                                return {
                                    ...prevPart,
                                    content: {
                                        type: 'picture',
                                        pictureUrl: imgUrl.trim(),
                                    },
                                }
                            } else {
                                return prevPart
                            }
                        }))
                    },
                )}
            /> : <Input
                bsSize='lg'
                className="solving-input"
                type="textarea"
                value={eotPart.content.pictureUrl}
                onChange={(e) => {
                    setEotParts(prev => prev.map(prevPart => {
                        if (prevPart.id === eotPart.id) {
                            check(prevPart.content.type === 'picture', 'po91cOiJA')
                            return {
                                ...prevPart,
                                content: {
                                    type: 'picture',
                                    pictureUrl: e.target.value.trim(),
                                },
                            }
                        } else {
                            return prevPart
                        }
                    }))
                }}
            />}
        </div>
    } else {
        assertNever(eotPart.content)
    }

    return <div key={eotPart.id} className="contributors-exercise-box">
        <div className="field-description">
            <span
                className="toggle-span"
                onClick={togglePart}
            >
                Toggle
            </span>
            <span
                onClick={deletePart}
                className="delete-span"
            >
                Delete
            </span>
        </div>
        <div>
            {content}
        </div>
    </div>
}
