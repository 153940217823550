import React from 'react'
import { Container } from "../utils/Container"
import { AppContext } from '../contexts/AppContext'
import { queryServer } from "../utils/queryServer"
import { useNavigate } from 'react-router-dom'
import { check } from '../utils/utilFunctions'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'

export const SubjectsAuthority = () => {
    const { gStatus } = React.useContext(AppContext)
    check(gStatus === 'leadModerator', 'lksivBVf5')

    const [subjectsAuthority, setSubjectsAuthority] = React.useState<RequestResponseTypes["getSubjectsAuthority"]["response"]>()

    const navigate = useNavigate()

    const getSubjectsAuthority = async () => {
        const result = await queryServer(
            'getSubjectsAuthority',
            undefined,
        )
        setSubjectsAuthority(result)
    }

    React.useEffect(() => {
        getSubjectsAuthority()
    }, [])

    let content
    if (subjectsAuthority == null) {
        content = <h3>Please wait...</h3>
    } else {
        content = <div>
            {subjectsAuthority.map(subject => {
                return <div
                    key={subject.subjectId}
                    className="subject-authority"
                    onClick={() => navigate(`/contribute/subjectAuthority/${subject.subjectId}`)}
                >
                    <h2>{subject.subjectInLocal}</h2>
                </div>
            })}
        </div>
    }

    return <Container
        title="Subjects authority"
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
        className="subjects-authority"
    >
        {content}
    </Container>
}
