import React from 'react'
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { queryServer } from "../utils/queryServer"
import { Col, Row, Button, Form, FormGroup, Label, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { SavedCollectionWithKeywords, useSavedCollectionsMetadata } from "../SavedCollectionsPage/useSavedCollectionsMetadata"
import { assertNever, check } from "../utils/utilFunctions"
import { ExerciseOrTheory, TOO_MANY_SAVED_COLLECTIONS, TOO_MANY_SAVED_ITEMS_IN_COLLECTION } from '../utils/Common/Common'
import { AppContext } from '../contexts/AppContext'
import { ModalContentProps } from '../utils/useModal'
import { Loader } from '../utils/Loader'
import classNames from 'classnames'
import { useLocalLanguage } from '../utils/useLocalLanguage'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'

// [22.01.2024.]
// TODO :
// - Add pagination for saved collections list in "existing" tab !!!!!!
//   Also pagination for Eots list on their pages (homePage/CollectionPage)
// - IMPROVE WHOLE LOGIC IN THIS MODAL, IT IS VERY BAD !!!!! BUT NOT RIGHT AWAY... DO IT LATER

// !!!!!! It should not be disabled saving exercise(s) in collection in they are already saved in!!!!!! It only
// should update timespamp added for that exercise if you add it again !!!!! To je bitno zbog cuvanja vise zadataka odjednom mislim
// ali za sad ne moras da cackas nista to, neka ostane ovako, pa za kasnije ce da se vidi sta ce i kako ce

type Props = {
    toSave: {
        kind: "single"
        type: ExerciseOrTheory
        eotId: string
    } | {
        kind: "test"
        exerciseIds: string[]
        testTitle: string
        testDescription: string | undefined
    }
}

export const MAX_COLLECTION_TITLE_LENGTH = 120
export const MAX_COLLECTION_DESCRIPTION_LENGTH = 250

export const SaveInCollection_Modal = ({
    params,
    closeModal,
}: ModalContentProps<Props>) => {
    const { inLocalLanguage } = useLocalLanguage()

    const { toSave } = params

    let eotInLocalLanguage: string | undefined = undefined
    if (toSave.kind === "single") {
        eotInLocalLanguage = toSave.type === 'exercise' ? inLocalLanguage("Exercise").toLowerCase() : (toSave.type === 'theory' ? inLocalLanguage("Theory").toLowerCase() : assertNever(toSave.type))
    } else if (toSave.kind === "test") {
        // leave eotInLocalLanguage undefined
    } else {
        assertNever(toSave)
    }

    const [alreadySavedInCollections, setAlreadySavedInCollections] = React.useState<Set<string>>()
    const getAlreadySavedInCollections = async (eotId: string) => {
        const response = await queryServer(
            'getAlreadySavedInCollections',
            { eotId },
        )
        setAlreadySavedInCollections(new Set(response.alreadySavedInCollections))
    }
    React.useEffect(() => {
        if (toSave.kind === "single") {
            getAlreadySavedInCollections(toSave.eotId)
        }
    }, [])

    const { gMyId } = React.useContext(AppContext)
    const savedCollections = useSavedCollectionsMetadata(gMyId)

    const [newOrExistingCollection, setNewOrExistingCollection] = React.useState<'new' | 'existing'>('new')

    const [selectedExistingCollection, setSelectedExistingCollection] = React.useState<SavedCollectionWithKeywords>()

    const [newCollectionTitle, setNewCollectionTitle] = React.useState(toSave.kind === "test" ? toSave.testTitle : "")
    const [newCollectionDescription, setNewCollectionDescription] = React.useState(toSave.kind === "test" ? toSave.testDescription ?? "" : "")
    const [newCollectionIsPrivate, setNewCollectionIsPrivate] = React.useState(false)

    // clickedSave_Tab[now/existing] will be helper at decideing whether to show message
    const [clickedSave_TabNew, setClickedSave_TabNew] = React.useState(false)
    const [clickedSave_TabExisting, setClickedSave_TabExisting] = React.useState(false)

    const [tooManyCollectionsPerUserError, setTooManyCollectionsPerUserError] = React.useState(false)
    const [tooManyItemsInCollectionError, setTooManyItemsInCollectionError] = React.useState(false)

    const [savingInProgress, setSavingInProgress] = React.useState(false)

    const save = async () => {
        if (savingInProgress) {
            console.log("Existing saving is still in process...")
            return
        }

        try {
            setSavingInProgress(true)

            let req: RequestResponseTypes["saveInCollection"]["request"]

            if (newOrExistingCollection === 'existing') {
                setClickedSave_TabExisting(true)

                // I think it is OK to delete max items in collection error
                setTooManyItemsInCollectionError(false)

                if (
                    selectedExistingCollection == null
                    || alreadySavedInCollections!.has(selectedExistingCollection.id)
                ) {
                    return
                }

                req = {
                    save: {
                        in: "existing",
                        collectionId: selectedExistingCollection.id,
                        itemsToSave: toSave.kind === "single" ? [{ id: toSave.eotId, itIs: toSave.type }] :
                            toSave.exerciseIds.map(exerciseId => ({
                                id: exerciseId,
                                itIs: "exercise"
                            })),
                    }
                }
            } else if (newOrExistingCollection === 'new') {
                setClickedSave_TabNew(true)

                if (newCollectionTitle.length === 0) {
                    return
                }

                check(
                    0 < newCollectionTitle.length && newCollectionTitle.length <= MAX_COLLECTION_TITLE_LENGTH
                    && newCollectionDescription.length <= MAX_COLLECTION_DESCRIPTION_LENGTH,
                    'mnNN6',
                )

                req = {
                    save: {
                        in: "new",
                        collection_title: newCollectionTitle,
                        collection_description: newCollectionDescription,
                        is_private: newCollectionIsPrivate,
                        itemsToSave: toSave.kind === "single" ? [{ id: toSave.eotId, itIs: toSave.type }] :
                            toSave.exerciseIds.map(exerciseId => ({
                                id: exerciseId,
                                itIs: "exercise"
                            })),
                    }
                }
            } else {
                assertNever(newOrExistingCollection)
            }

            await queryServer(
                'saveInCollection',
                req,
            )

            closeModal()
        } catch (e: any) {
            if (e.message === TOO_MANY_SAVED_COLLECTIONS) {
                setTooManyCollectionsPerUserError(true)
            } else if (e.message === TOO_MANY_SAVED_ITEMS_IN_COLLECTION) {
                setTooManyItemsInCollectionError(true)
            } else {
                throw e
            }
        } finally {
            setSavingInProgress(false)
        }
    }

    let formContent
    let message
    if (newOrExistingCollection === 'new') {
        formContent = <>
            <Row form className='mg-b-10'>
                <Col md={12}>
                    <Input
                        type="text"
                        placeholder={inLocalLanguage('Collection title')}
                        onChange={e => setNewCollectionTitle(e.target.value.substring(0, MAX_COLLECTION_TITLE_LENGTH))}
                        value={newCollectionTitle}
                        style={{
                            fontSize: '16px',
                            border: clickedSave_TabNew && newCollectionTitle.length === 0 ? "1px solid red" : undefined,
                        }}
                    />
                    {newCollectionTitle.length === MAX_COLLECTION_TITLE_LENGTH && <span style={{
                        color: 'orange',
                    }}>{inLocalLanguage('Reached collection title maximum length')}</span>}
                </Col>
            </Row>
            <Row form className="mg-b-10">
                <Col md={12}>
                    <Input
                        type="textarea"
                        placeholder={inLocalLanguage("Collection description (optional)")}
                        onChange={e => setNewCollectionDescription(e.target.value.substring(0, MAX_COLLECTION_DESCRIPTION_LENGTH))}
                        value={newCollectionDescription}
                        style={{ fontSize: '16px' }}
                    />
                    {newCollectionDescription.length === MAX_COLLECTION_DESCRIPTION_LENGTH && <span style={{
                        color: 'orange',
                    }}>{inLocalLanguage('Reached collection description maximum length')}</span>}
                </Col>
            </Row>
            {false && <Row form>
                <Col md={12}>
                    <FormGroup>
                        <UncontrolledDropdown>
                            <DropdownToggle caret outline style={{ fontSize: '16px' }}>
                                {newCollectionIsPrivate === true ? inLocalLanguage('Private collection') : inLocalLanguage('Public collection')}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    style={{ background: newCollectionIsPrivate ? '#e0ecff' : undefined, fontSize: '16px' }}
                                    onClick={() => setNewCollectionIsPrivate(true)}
                                >
                                    {inLocalLanguage('Private collection')}
                                </DropdownItem>
                                <DropdownItem
                                    style={{ background: !newCollectionIsPrivate ? '#e0ecff' : undefined, fontSize: '16px' }}
                                    onClick={() => setNewCollectionIsPrivate(false)}
                                >
                                    {inLocalLanguage('Public collection')}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </FormGroup>
                </Col>
            </Row>}
        </>

        if (tooManyCollectionsPerUserError) {
            message = <span style={{ color: "red" }}>{inLocalLanguage('Reached max collections/user')}</span>
        } else if (clickedSave_TabNew && newCollectionTitle.length === 0) {
            message = <span style={{ color: "red" }}>{inLocalLanguage('Collection title required')}</span>
        }
    } else if (newOrExistingCollection === 'existing') {
        if (alreadySavedInCollections == null || savedCollections == null) {
            formContent = <Loader />
        } else if (savedCollections.sortedAndFilteredCollections.length === 0) {
            formContent = <h4>{inLocalLanguage("You dont have saved collections")}</h4>
        } else {
            formContent = <div className='saved-buckets-list'>
                <h3 className='mg-t-0 mg-b-20' style={{ color: 'rgb(27, 132, 231)' }}>
                    {inLocalLanguage("Collections saved by you")}
                </h3>
                <div className="buckets-filter-box">
                    <input
                        type="text"
                        placeholder={inLocalLanguage('Find collection')}
                        onChange={e => savedCollections.setTypingKeywords(e.target.value)}
                        value={savedCollections.typingKeywords}
                        style={{ fontSize: '16px' }}
                    />
                </div>
                <div>
                    {savedCollections.sortedAndFilteredCollections.map(collection => <div key={collection.id}>
                        <div
                            className={classNames("bucket-in-list", {
                                "selected-bucket-error": selectedExistingCollection?.id === collection.id && alreadySavedInCollections.has(collection.id),
                                "selected-bucket-success": selectedExistingCollection?.id === collection.id && !alreadySavedInCollections.has(collection.id),
                            })}
                            onClick={() => {
                                // THIS CAN DEFENETELY BE BUGGY (e.g. If user gets an error but
                                // in meantime the user changed selection). But for now let it be [22.01.2024.]
                                setTooManyItemsInCollectionError(false)
                                setSelectedExistingCollection(collection)
                            }}
                            style={{ marginBottom: 0 }}
                        >
                            {collection.collection_title}
                            <div style={{
                                marginTop: "6px",
                                color: "rgb(134, 139, 161)",
                                fontSize: "78%",
                            }}>{collection.collection_description}</div>
                        </div>
                    </div>)}
                </div>
            </div>
        }

        if (tooManyItemsInCollectionError) {
            message = <span style={{ color: "red" }}>{inLocalLanguage('Reached max items/collection')}</span>
        } else if (selectedExistingCollection == null) {
            if (clickedSave_TabExisting) {
                message = <span style={{ color: "red" }}>{inLocalLanguage('Select collection')}</span>
            }
        } else {
            if (alreadySavedInCollections!.has(selectedExistingCollection.id)) {
                message = <span style={{ color: "red" }}>
                    {inLocalLanguage('Already saved')} {eotInLocalLanguage} {inLocalLanguage('In').toLowerCase()} "<span style={{ fontWeight: "bold" }}>{selectedExistingCollection.collection_title}</span>"
                </span>
            } else {
                message = <span style={{ color: "green" }}>
                    {inLocalLanguage("Save")} {eotInLocalLanguage} {inLocalLanguage('In').toLowerCase()} "<span style={{ fontWeight: "bold" }}>{selectedExistingCollection.collection_title}</span>"
                </span>
            }
        }
    } else {
        assertNever(newOrExistingCollection)
    }

    return <div className="save-in-bucket-modal responsive-modal-box" style={{ width: "620px" }}>
        <ModalHeader toggle={closeModal}>{inLocalLanguage("Save")} {toSave.kind === "single" ? eotInLocalLanguage : "test"}</ModalHeader>
        <ModalBody>
            {toSave.kind === "single" && <FormGroup tag="fieldset">
                <FormGroup check>
                    <Label check onClick={() => setNewOrExistingCollection('new')} style={{ fontSize: '16px' }}>
                        <Input type="radio" name="radio1" checked={newOrExistingCollection === 'new'} />{' '}
                        {inLocalLanguage("Save")} {eotInLocalLanguage} {inLocalLanguage("In new collection").toLowerCase()}
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check onClick={() => setNewOrExistingCollection('existing')} style={{ fontSize: '16px' }} >
                        <Input type="radio" name="radio1" checked={newOrExistingCollection === 'existing'} />{' '}
                        {inLocalLanguage("Save")} {eotInLocalLanguage} {inLocalLanguage('In existing collection').toLowerCase()}
                    </Label>
                </FormGroup>
            </FormGroup>}
            <Form>
                {formContent}
            </Form>
        </ModalBody>
        <ModalFooter>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    {message}
                </div>
                <Button style={{ maxHeight: "54px" }} className='mg-l-10' color="success" onClick={save}>
                    {inLocalLanguage("Save")} {savingInProgress && <Loader
                        className='mg-l-10'
                        style={{ width: '15px', height: '15px' }}
                    />}
                </Button>
                <Button style={{ maxHeight: "54px" }} className='mg-l-10' color="secondary" onClick={closeModal}>
                    {inLocalLanguage('Cancel')}
                </Button>
            </div>
        </ModalFooter>
    </div>
}
