import React, { useState } from 'react'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { ModalContentProps, useModal } from './useModal'
import { useLocalLanguage } from './useLocalLanguage'
import ReactCrop, { centerCrop, makeAspectCrop, PixelCrop, type Crop } from 'react-image-crop'
import { Loader } from './Loader'
import { useDropzone } from 'react-dropzone'

export const useDropAndCropImage = (
    modalTitle: string,
    submitButtonTitle: string,
    cropShape: "circle" | "square",
    onSubmit: (
        croppedImageUrl: string,
        croppedImageWidth: number | undefined,
        croppedImageHeight: number | undefined,
        closeModal: () => void,
    ) => Promise<void>,
    renderDropzone: (dropzoneState: ReturnType<typeof useDropzone>) => React.ReactNode,
) => {
    const cropImageModal = useModal(ImageCropModal)

    const dropzoneState = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            cropImageModal.show({
                fullImageUrl: acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))[0].preview,
                cropShape,
                onSubmit,
                modalTitle,
                submitButtonTitle,
            })
        },
        maxFiles: 1,
    })

    return <>
        {renderDropzone(dropzoneState)}
        {cropImageModal.render()}
    </>
}

type Props = {
    modalTitle: string
    submitButtonTitle: string
    fullImageUrl: string
    cropShape: "circle" | "square"
    onSubmit: (
        croppedImageUrl: string,
        croppedImageWidth: number | undefined,
        croppedImageHeight: number | undefined,
        closeModal: () => void,
    ) => Promise<void>
}

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
    cropShape: "circle" | "square",
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: cropShape === "circle" ? 300 / mediaWidth * 100 : 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const ImageCropModal = ({
    params,
    closeModal,
}: ModalContentProps<Props>) => {
    const {
        fullImageUrl,
        onSubmit,
        cropShape,
        modalTitle,
        submitButtonTitle,
    } = params

    const imgRef = React.useRef<HTMLImageElement>(null)
    const [crop, setCrop] = React.useState<Crop>()
    const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>()

    const { inLocalLanguage } = useLocalLanguage()

    const [submitting, setSubmitting] = useState(false)

    const submit = async () => {
        if (submitting) {
            return
        }

        try {
            setSubmitting(true)

            if (completedCrop != null && imgRef.current != null) {
                const croppedImageUrl = await getCroppedImageUrl()
                await onSubmit(
                    croppedImageUrl,
                    completedCrop.width,
                    completedCrop.height,
                    closeModal,
                )
            }
        } finally {
            setSubmitting(false)
        }
    }

    // ChatGPT optimized function from
    // https://codesandbox.io/p/sandbox/react-image-crop-demo-with-react-hooks-y831o?file=%2Fsrc%2FApp.tsx%3A78%2C15
    async function getCroppedImageUrl(): Promise<string> {
        const image = imgRef.current
        if (!image || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }

        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const maxDimension = 1000
        const scaleFactor = Math.min(
            maxDimension / (completedCrop.width * scaleX),
            maxDimension / (completedCrop.height * scaleY),
            1,
        )

        const canvas = document.createElement('canvas')
        canvas.width = completedCrop.width * scaleX * scaleFactor
        canvas.height = completedCrop.height * scaleY * scaleFactor
        const ctx = canvas.getContext('2d')
        if (!ctx) throw new Error('No 2d context')

        const imageBitmap = await createImageBitmap(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY
        )

        ctx.imageSmoothingEnabled = false
        ctx.drawImage(imageBitmap, 0, 0, canvas.width, canvas.height)

        const blob = await new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (blob) resolve(blob)
                    else reject(new Error('Failed to create blob'))
                },
                'image/jpeg',
                1,
            )
        }) as Blob

        return URL.createObjectURL(blob)
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget
        const aspect = cropShape === 'circle' ? 1 : 16 / 9
        setCrop(centerAspectCrop(width, height, aspect, cropShape))
    }

    return <div className='responsive-modal-box' style={{ width: "740px" }}>
        <ModalHeader toggle={closeModal}>{modalTitle}</ModalHeader>
        <ModalBody style={{ padding: 0 }}>
            <div style={{
                padding: '0 15px',
                width: '100%',
                maxHeight: 'inherit',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    keepSelection
                    circularCrop={cropShape === 'circle'}
                    aspect={cropShape === 'circle' ? 1 : 0}
                >
                    <img
                        ref={imgRef}
                        src={fullImageUrl}
                        onLoad={onImageLoad}
                        className='mw-100'
                    />
                </ReactCrop>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={submit}>
                {submitButtonTitle} {submitting && <Loader
                    className='mg-l-10'
                    style={{ width: '15px', height: '15px' }}
                />}
            </Button>
            <Button color="secondary" onClick={closeModal}>{inLocalLanguage("Cancel")}</Button>
        </ModalFooter>
    </div>
}
