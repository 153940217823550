import { ExerciseOrTheory } from "../utils/Common/Common"
import { ChosenSubjectAndGrade } from "./HomeData"

type LastFilters = {
    subjectAndGrade: ChosenSubjectAndGrade | undefined
    eot: ExerciseOrTheory | undefined
    theoryKeywords: string | undefined
    exerciseKeywords: string | undefined
    exerciseArea: string | undefined
    exercisePage: number | undefined
    exerciseDifficulty: 'easy' | 'medium' | 'hard' | undefined
    lastGeneratedExercisesTest: {
        testId: string
        generatedAt: string // formated timestamp
        exerciseIds: string[]
    } | undefined
    allExercisesOrTests: "allExercises" | "testsExercises" | undefined
}

const initialFilters = (): LastFilters => {
    return {
        subjectAndGrade: undefined,
        eot: undefined,
        exerciseArea: undefined,
        exerciseKeywords: undefined,
        theoryKeywords: undefined,
        exercisePage: undefined,
        exerciseDifficulty: undefined,
        lastGeneratedExercisesTest: undefined,
        allExercisesOrTests: "allExercises",
    }
}

export const LAST_FILTERS: LastFilters = initialFilters()
