import { queryServer } from "./queryServer"

export async function getSignedPutUrl(
    bucketName: string,
    contentType: string,
): Promise<string | null> {
    const signedUrlResponse = await queryServer(
        'getSignedPutUrl',
        { bucketName, contentType },
    )

    if (signedUrlResponse.success) {
        return signedUrlResponse.signedPutUrl
    } else {
        return null
    }
}
