import React from 'react'
import '@js-joda/timezone'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { AppContext } from '../contexts/AppContext'
import { queryServer } from "../utils/queryServer"
import { Col, Row, Button, FormGroup, Input } from 'reactstrap'
import { getChatName, getChatProfilePicture } from "../MessagesPage/ChatsPage"
import { nanoid } from "nanoid"
import { getProfileImageURL, onProfileImageError } from "../Header/Header"
import { useLastNChats } from '../MessagesPage/useLastNChats'
import { useLocalLanguage } from '../utils/useLocalLanguage'
import { UserBasics, ChatDoc, ExerciseOrTheory } from '../utils/Common/Common'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'

type EntityToSend = {
    type: ExerciseOrTheory
    entityId: string
}

type Props = {
    modalIsOpen: boolean
    toggleModal: () => void
    entityToSend: EntityToSend
}

// ........ This modal gives warning ........ fix this ........
export const SendEntitiesModal = (props: Props) => {
    const [usersFirstName, setUsersFirstname] = React.useState('')
    const [usersLastName, setUsersLastname] = React.useState('')
    const [foundMembers, setFoundMembers] = React.useState<UserBasics[]>([])
    const [foundMembersError, setFoundMembersError] = React.useState<null | string>(null)

    const {
        gMyId,
    } = React.useContext(AppContext)

    const {
        chats,
        readMoreChats,
    } = useLastNChats()

    const { inLocalLanguage } = useLocalLanguage()

    const sendMessageHandler = async (data: RequestResponseTypes["sendMessage"]["request"]) => {
        const response = await queryServer(
            'sendMessage',
            data,
        )

        //const chatId = privateChatIdFromUserIds(gMyId)
        // Maybe move this (and ALL chat send messages) logic to backend ???? !!!!
        // In that case, client-firestore would just listen for changes.
        // const memberMe: UserBasics = {
        //     firstname: gFirstname,
        //     lastname: gLastname,
        //     userId: gMyId,
        // }

        // try {
        //     //
        //     // Prvo proveri da li chat postoji.
        //     // Ako ne postoji, prvo ga napravi, pa onda posalji poruku
        //     // A ako postoji, odma posalji poruku
        //     //
        //     const chatRefExistCheck = firebase.firestore().collection("Chats").doc(chatId)
        //     const chatSnapshot = await chatRefExistCheck.get()
        //     if (!chatSnapshot.exists) {
        //         // If chat doesn't exist, create it

        //         // Object.fromEntries just from version Node 12
        //         const lastDeletingsTs: { [memberId: string]: number } = Object.fromEntries(availableForMembers.map(userId => {
        //             return [userId, 0]
        //         }))

        //         const chat: ChatDoc = {
        //             chatId: chatId,
        //             members: [memberMe, otherUser!],
        //             memberIds: availableForMembers,
        //             availableForMembers: [],
        //             lastMessage: {
        //                 timeSent: 0,
        //                 sentBy: memberMe, // arbitrarily, because there is no effect
        //                 messagePreview: '',
        //                 shouldBeSeenByUsers: []
        //             },
        //             lastDeletingsTs,
        //             type: 'private',
        //             chatName: null
        //         }

        //         await chatRefExistCheck.set(chat)
        //     }
        //     // posalji poruku:
        //     const batch = firebase.firestore().batch()

        //     const nowTs = ZonedDateTime.now(ZoneId.of('Europe/Belgrade')).toEpochSecond()
        //     const messageId = nanoid()

        //     const messageFull: MessageFull = props.entitiesToSend.map(eotToSend => ({
        //         text: eotToSend.details.text,
        //         entityId: eotToSend.entityId,
        //         eotType: eotToSend.details.type,
        //     }))

        //     const messageDoc: MessageDoc = {
        //         messageId,
        //         timeSent: nowTs,
        //         fromUser: {
        //             userId: gMyId,
        //             firstname: gFirstname,
        //             lastname: gLastname,
        //         },
        //         // toUserId: props.chatIdAndChatMembers.userId,
        //         //messagePreviewForChatsList: messageText,
        //         messagePreview: messageFull[0].text,
        //         messageFull,
        //     }

        //     const chatValuesToUpdate = {
        //         // again (even if some user deleted chat), set available chat for every participant
        //         availableForMembers,
        //         lastMessage: {
        //             timeSent: nowTs,
        //             sentBy: memberMe,
        //             messagePreview: props.messagePreview,
        //             // set shouldBeSeenByUsers to all users, but me
        //             shouldBeSeenByUsers: availableForMembers.filter(it => it !== gMyId),
        //         }
        //     }

        //     const chatRef = firebase.firestore().collection("Chats").doc(chatId)

        //     const messageRef = chatRef.collection('Messages').doc(messageId)

        //     batch.set(messageRef, messageDoc)
        //     batch.update(chatRef, chatValuesToUpdate)

        //     await batch.commit()
        // } catch (e) {
        // }
    }

    const findUsers = async () => {
        // try {
        //     setFoundMembersError(null)
        //     setFoundMembers([])
        //     const response = await queryServer(
        //         'findUsers',
        //         {
        //             firstname: usersFirstName,
        //             lastname: usersLastName,
        //         },
        //     )

        //     setFoundMembers(response.users)
        // } catch (e: any) {
        //     setFoundMembersError(e.message ?? 'Unknown error')
        // }
    }

    const renderFoundUser = (foundUser: UserBasics): JSX.Element => {
        if (foundUser.id === gMyId) {
            return <div className="list-group list-group-user" key={foundUser.id}>
                <div
                    className="list-group-item"
                >
                    <img src={getProfileImageURL(foundUser.id)} onError={onProfileImageError} alt="profile image" />
                    <div className="user-name-address">
                        <p>{foundUser.firstname} {foundUser.lastname}</p>
                        <span>Clemson, CA</span>
                    </div>
                    <div className="user-btn-wrapper">
                        <span>
                            This is you
                        </span>
                    </div>
                </div>
            </div>
        } else {
            return <div className="list-group list-group-user" key={foundUser.id}>
                <div
                    className="list-group-item"
                >
                    <img src={getProfileImageURL(foundUser.id)} onError={onProfileImageError} alt="profile image" />
                    <div className="user-name-address">
                        <p>{foundUser.firstname} {foundUser.lastname}</p>
                        <span>Clemson, CA</span>
                    </div>
                    <div className="user-btn-wrapper">
                        <span
                            className="delete-friend"
                            onClick={() => sendMessageHandler(
                                {
                                    type: 'unknownChatId',
                                    messageFull: {
                                        type: props.entityToSend.type,
                                        eotId: props.entityToSend.entityId,
                                        eotPreview: '__eot__preview__',
                                    },
                                    sendToUser: foundUser,
                                }
                            )}
                        >
                            {inLocalLanguage('Send')}
                        </span>
                    </div>
                </div>
            </div>
        }
    }

    const renderChat = (chat: ChatDoc) => {
        return <div className="list-group list-group-user" key={chat.chatId}>
            <div
                className="list-group-item"
            >
                <img src={getChatProfilePicture(chat.members, chat.details.type, gMyId)} onError={onProfileImageError} alt="chat image" />
                <div className="user-name-address">
                    <p>{getChatName(gMyId, chat.members, chat.details)}</p>
                    <span>nesto mozda vrvvv...</span>
                </div>
                <div className="user-btn-wrapper">
                    <span
                        className="delete-friend"
                        onClick={() => sendMessageHandler({
                            type: 'knownChatId',
                            chatId: chat.chatId,
                            messageFull: {
                                type: props.entityToSend.type,
                                eotId: props.entityToSend.entityId,
                                eotPreview: '__eot__preview__',
                            },
                            messageId: nanoid(),
                        })}
                    >
                        {inLocalLanguage('Send')}
                    </span>
                </div>
            </div>
        </div>
    }

    const renderChats = () => {
        if (chats?.chats == null) {
            return <h4>Please wait...</h4>
        } else if (chats.chats.length === 0) {
            return <h4>Still no chats.</h4>
        } else {
            return <div>
                {chats.chats.map(chat => renderChat(chat))}
                {readMoreChats != null && <span className='span-btn' onClick={readMoreChats}>
                    Load older chats
                </span>}
            </div>
        }
    }

    const form = <div className="section-wrapper" style={{ marginTop: '10px', border: '10px', borderColor: 'red', zIndex: 10000 }}>
        <div className="find-friend-card">
            <div style={{ marginBottom: "5px" }}>Find users</div>
            <div className="search-friend">
                <Row form>
                    <Col md={4}>
                        <FormGroup>
                            <Input
                                type="text"
                                placeholder="Ime"
                                id="name"
                                value={usersFirstName}
                                onChange={e => setUsersFirstname(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Input
                                type="text"
                                placeholder="Prezime"
                                id="lastname"
                                value={usersLastName}
                                onChange={e => setUsersLastname(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Button className="find-friend-btn" onClick={findUsers}>
                                Find
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            {foundMembersError && <p>{foundMembersError}</p>}
            {foundMembers.length > 0 && <div className="row row-sm mg-t-20">
                <div className="col-lg-12">
                    <div className="card-header describe-header">
                        <h6 className="slim-card-title">Lista pronadjenih korisnika</h6>
                    </div>
                    {foundMembers.map(foundUser => renderFoundUser(foundUser))}
                </div>
            </div>}
        </div>

        <div className="row row-sm mg-t-20">
            <Input
                type="text"
                placeholder="delete this, its not neccessary"
                id="filter-chat"
            />
            <div className="col-lg-12">
                <div className="card-header describe-header">
                    <h6 className="slim-card-title">Existing chats</h6>
                </div>
                {renderChats()}
            </div>
        </div>
    </div>

    return <Modal
        isOpen={props.modalIsOpen}
        toggle={props.toggleModal}
        className="save-eot-modal"
    >
        <ModalHeader toggle={props.toggleModal}>{inLocalLanguage('Send')}</ModalHeader>
        <ModalBody>
            {form}
        </ModalBody>
    </Modal>
}
