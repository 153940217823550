import React from "react"
import { AppContext } from "../contexts/AppContext"
import { COUNTRIES, LanguageInEnglish } from "./Common/Common"
import { checkNotNull } from "./utilFunctions"
import { PossibleWebAppText, webAppTextsByCountries } from "./ServiceLocal"

export const useLocalLanguage = () => {
    const { gCountryId } = React.useContext(AppContext)

    const language: LanguageInEnglish = React.useMemo(() => {
        return checkNotNull(COUNTRIES.find(it => it.id === gCountryId), 'ycb7YdF').language_in_english
    }, [gCountryId])

    const inLocalLanguage = (text: PossibleWebAppText) => {
        return webAppTextsByCountries[text][language]
    }

    return {
        inLocalLanguage,
    }
}
