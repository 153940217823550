import React from "react"
import { queryServer } from "../utils/queryServer"
import {
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap'
import Select from 'react-select'
import { assertNever } from '../utils/utilFunctions'

type ExerciseInTest = {
    exerciseOrderNumber: number
    rules: {
        gradeAreaId: { areaId: string, area_name: string }
        difficulty: 'easy' | 'medium' | 'hard'
        probability: number
    }[]
}

type Props = {
    selectedGrade: {
        id: string
        grade_in_local: string
    }
    selectedGradeArea: {
        areaId: string
        area_name: string
    } | undefined
}

export const CreateTest = ({ selectedGrade, selectedGradeArea }: Props) => {
    const [testTitle, setTestTitle] = React.useState<string>('')
    const [testDescription, setTestDescription] = React.useState<string>('')
    const [testOrderNumber, setTestOrderNumber] = React.useState<number>(0)

    const [difficulty, setDifficulty] = React.useState<{ option: 'easy' | 'medium' | 'hard' } | null>(null)
    const [probability, setProbability] = React.useState(0)

    const [currentTestExercises, setCurrentTestExercises] = React.useState<ExerciseInTest[]>([])
    const [currentlyMakingExercise, setCurrentlyMakingExercise] = React.useState<ExerciseInTest>({ exerciseOrderNumber: 0, rules: [] })

    const addRule = (
        area: { areaId: string, area_name: string },
        difficulty: 'easy' | 'medium' | 'hard',
        probability: number,
    ) => {
        setCurrentlyMakingExercise(prev => {
            return {
                exerciseOrderNumber: prev.exerciseOrderNumber,
                rules: [
                    ...prev.rules,
                    {
                        gradeAreaId: { areaId: area.areaId, area_name: area.area_name },
                        difficulty: difficulty,
                        probability: probability,
                    },
                ],
            }
        })
    }

    const deleteRule = (id: number) => {
        const ruleExercises = currentlyMakingExercise.rules.filter((rule, index) => index !== id)
        setCurrentlyMakingExercise(prev => {
            return {
                exerciseOrderNumber: prev.exerciseOrderNumber,
                rules: ruleExercises
            }
        })
    }

    const currentlyMakingProbabilitySum = currentlyMakingExercise.rules.reduce((s, { probability }) => s + probability, 0)

    const addExerciseToTest = () => {
        setCurrentTestExercises(prev => {
            return [...prev, currentlyMakingExercise]
        })
        setDifficulty(null)
        setProbability(0)
        setCurrentlyMakingExercise({ exerciseOrderNumber: 0, rules: [] })
    }

    const createTest = async () => {
        const exercises = currentTestExercises.map(exercise => ({
            exerciseOrderNumber: exercise.exerciseOrderNumber,
            rules: exercise.rules.map(rule => ({
                difficulty: rule.difficulty,
                gradeAreaId: rule.gradeAreaId.areaId,
                probability: rule.probability,
            }))
        }))

        const result = await queryServer(
            'createTest',
            {
                testInGrade: selectedGrade.id,
                testTitle: testTitle,
                testDescription: testDescription,
                testOrderNumber: testOrderNumber,
                exercises: exercises
            },
        )

        if (result) {
            location.reload()
        }
    }

    const options = [{ option: 'easy' }, { option: 'medium' }, { option: 'hard' }] as const

    return <>
        <div style={{ marginTop: '20px', border: '2px solid orange', padding: '20px' }}>
            <FormGroup>
                <Label>Exercise order number</Label>
                <Input
                    type="number"
                    value={currentlyMakingExercise.exerciseOrderNumber}
                    onChange={e => setCurrentlyMakingExercise(prev => ({ exerciseOrderNumber: Number(e.target.value), rules: prev.rules }))}
                    placeholder="Exercise order number"
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                />
            </FormGroup>
            <FormGroup>
                <Label style={{ marginBottom: "2px" }}>Choose Difficulty</Label>
                <Select
                    isSearchable={false}
                    options={options}
                    value={difficulty}
                    getOptionLabel={(option) => {
                        if (option.option === 'easy') {
                            return "Easy"
                        } else if (option.option === 'hard') {
                            return 'Hard'
                        } else if (option.option === 'medium') {
                            return 'Medium'
                        } else {
                            assertNever(option.option)
                        }
                    }}
                    onChange={option => setDifficulty(option ?? null)}
                    placeholder="Chose"
                    isOptionSelected={option => option.option === difficulty?.option}
                    isClearable
                    styles={{
                        control: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                        option: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                    }}
                />
            </FormGroup>
            <FormGroup>
                <Label>Probability</Label>
                <Input
                    type="number"
                    value={probability}
                    onChange={e => setProbability(Number(e.target.value))}
                    placeholder="Probability"
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                />
            </FormGroup>
            <div>
                <Button
                    disabled={selectedGradeArea == null || difficulty == null}
                    onClick={() => selectedGradeArea && difficulty && addRule(selectedGradeArea, difficulty.option, probability)}
                >
                    Add rules for task
                </Button>
                {currentlyMakingExercise.rules.length != 0 &&
                    <div style={{ margin: '10px' }}>
                        {currentlyMakingExercise.rules.map((el, index) => <div key={index} onClick={() => deleteRule(index)}>
                            <span>{el.probability}% </span>
                            <span>{el.gradeAreaId.area_name} - </span>
                            <span>{el.difficulty} </span>
                        </div>)}
                    </div>
                }
            </div>
            <div style={{ marginTop: '10px' }}>
                <Button
                    disabled={currentlyMakingProbabilitySum !== 100 || currentTestExercises.map(el => el.exerciseOrderNumber).includes(currentlyMakingExercise.exerciseOrderNumber)}
                    onClick={() => addExerciseToTest()}
                >
                    Create exercise
                </Button>
                {currentTestExercises.length != 0 &&
                    <div style={{ padding: '10px' }}>
                        <h4>Created exercises</h4>
                        {currentTestExercises.map((el, index) => <div key={index} className='mg-b-10'>{el.exerciseOrderNumber}: {el.rules.map((rule, index) => <div key={index}>
                            <span>{rule.probability}% </span>
                            <span>{rule.gradeAreaId.area_name} - </span>
                            <span>{rule.difficulty} </span>
                        </div>)}</div>)}
                    </div>
                }
            </div>
        </div>
        <div style={{ marginTop: '20px', border: '2px solid blue', padding: '20px' }}>
            <FormGroup>
                <Label>Test order number</Label>
                <Input
                    type="number"
                    value={testOrderNumber}
                    onChange={e => setTestOrderNumber(Number(e.target.value))}
                    placeholder="Test order number"
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                />
            </FormGroup>
            <FormGroup>
                <Label>Test title</Label>
                <Input
                    type="text"
                    value={testTitle}
                    onChange={e => setTestTitle(e.target.value)}
                    placeholder="Test title"
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                />
            </FormGroup>
            <FormGroup>
                <Label>Test description</Label>
                <Input
                    type="text"
                    value={testDescription}
                    onChange={e => setTestDescription(e.target.value)}
                    placeholder="Test description"
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                />
            </FormGroup>
            <div style={{ marginTop: '10px' }}>
                <Button
                    disabled={testTitle == null && testDescription == null}
                    onClick={() => testTitle && testDescription && createTest()}
                >
                    Create test
                </Button>
            </div>
        </div>
    </>
}