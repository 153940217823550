import React, { useState } from 'react'
import { Container } from "../utils/Container"
import { queryServer } from '../utils/queryServer'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import Select from 'react-select'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import { IsbnParsed } from '../utils/Common/Common'

export const OptimizationsPage = () => {
    const [optimization, setOptimization] = React.useState<RequestResponseTypes["getOptimizations_contributor"]["response"] | null>()

    const [allowedIsbns, setAllowedIsbns] = React.useState<IsbnParsed[]>()

    const getAllowedIsbns = async () => {
        const response = await queryServer(
            'getAllowedIsbns',
            undefined,
        )

        setAllowedIsbns(response.data)
    }

    const getOptimization = async () => {
        const response = await queryServer(
            'getOptimizations_contributor',
            undefined,
        )
        setOptimization(response ?? null)
    }

    React.useEffect(() => {
        getOptimization()
        getAllowedIsbns()
    }, [])

    // data for creating new optimization
    const [selectedIsbn, setSelectedIsbn] = React.useState<IsbnParsed>()
    const [isbns, setIsbns] = React.useState<IsbnParsed[]>([])
    const [description, setDescription] = React.useState('')

    if (optimization === undefined || allowedIsbns == null) {
        return <h3>Please wait...</h3>
    }

    return <Container
        title='optimizations'
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: false,
        }}
    >
        <div className='contributors-exercise-page'>
            {optimization === null && <h3>Still no optimization for your country</h3>}
            {optimization !== null && <div className='pd-5 mg-10' style={{
                border: "1px solid gray",
            }}>
                <div>{optimization.description}</div>
                <ul className='mg-10'>
                    {optimization.isbns.map((it, index) => <li key={index}>[{it.isbn}] {it.bookTitle}</li>)}
                </ul>

                <DeleteOptimization optimizationId={optimization.optimizationId} />
            </div>}
            {/* only one optimization per country is allowed */}
            {optimization === null && <div className='mg-t-30 pd-20' style={{ borderTop: "1px solid blue" }}>
                <h5>Add new optimization</h5>

                <div className='mg-b-30 mg-t-20'>
                    <Select
                        className='mg-b-10'
                        isSearchable={false}
                        options={allowedIsbns!}
                        value={selectedIsbn}
                        getOptionLabel={(option) => `[${option.isbn}] ${option.bookTitle}`}
                        onChange={option => {
                            setSelectedIsbn(option ?? undefined)
                        }}
                        isOptionSelected={option => option.isbn === selectedIsbn?.isbn}
                        placeholder="Chose ISBN"
                        styles={{
                            control: (base) => ({
                                ...base,
                                fontSize: "16px",
                            }),
                            option: (base, r) => ({
                                ...base,
                                fontSize: "16px",
                            }),
                        }}
                    />

                    {selectedIsbn && <span
                        className='span-btn'
                        onClick={() => {
                            const alreadyHere = isbns.find(it => it.isbn === selectedIsbn.isbn) != null
                            if (!alreadyHere) {
                                setIsbns(prev => prev.concat(selectedIsbn))
                            }
                        }}
                    >
                        Add selected isbn to optimization
                    </span>}

                    <ul className='mg-t-10'>
                        {isbns.map((it, index) => {
                            return <li key={index}>
                                [{it.isbn}] {it.bookTitle} &nbsp;  <span
                                    className='span-btn'
                                    onClick={() => {
                                        setIsbns(prev => prev.filter(p => p.isbn !== it.isbn))
                                    }}
                                >
                                    Remove
                                </span>
                            </li>
                        })}
                    </ul>
                </div>

                <FormGroup>
                    <Label>Description</Label>
                    <Input
                        type="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </FormGroup>
                <Button
                    onClick={async () => {
                        await queryServer(
                            'addOrDeleteOptimization',
                            {
                                todo: "add",
                                isbns: isbns.map(it => it.isbn),
                                description,
                            },
                        )
                        location.reload()
                    }}
                    disabled={isbns.length === 0 || description.length === 0}
                >
                    Save new optimization
                </Button>
            </div>}
        </div>
    </Container>
}

const DeleteOptimization = ({ optimizationId }: { optimizationId: string }) => {
    const [clicked, setClicked] = useState(false)

    return <div className='mg-b-20 mg-t-20'>
        <span
            className='span-btn'
            style={{ color: clicked ? "red" : undefined }}
            onClick={async () => {
                if (!clicked) {
                    setClicked(true)
                } else {
                    await queryServer(
                        'addOrDeleteOptimization',
                        {
                            todo: 'delete',
                            optimizationId,
                        },
                    )

                    location.reload()
                }
            }}
        >
            {clicked ? "Click again to confirm delete" : "Delete optimization"}
        </span>
        {clicked && <span className='span-btn' onClick={() => setClicked(false)}>Cancel</span>}
    </div>
}
