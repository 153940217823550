import React from 'react'
import { CommentOnDetails, ExerciseOrTheory, TopCommentOnDetails } from '../utils/Common/Common'
import { useUpdateLastCheck } from '../utils/useUpdateLastCheck'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { assertNever } from '../utils/utilFunctions'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'

type Props = {
    notifications: RequestResponseTypes["getNotifications"]["response"]
}

export const NotificationsList = ({ notifications }: Props) => {
    const { updateLastNotificationsCheck } = useUpdateLastCheck()
    const navigate = useNavigate()

    React.useEffect(() => {
        updateLastNotificationsCheck(notifications.readNotificationsToTs)
    }, [])

    const getEotId_helper = (commentOnDetails: TopCommentOnDetails): { eot: ExerciseOrTheory, eotId: string } => {
        if (commentOnDetails.on === 'exercise_part') {
            return {
                eot: 'exercise',
                eotId: commentOnDetails.exerciseId,
            }
        } else if (commentOnDetails.on === 'exercise_video') {
            return {
                eot: 'exercise',
                eotId: commentOnDetails.exerciseId,
            }
        } else if (commentOnDetails.on === 'theory_part') {
            return {
                eot: 'theory',
                eotId: commentOnDetails.theoryId,
            }
        } else if (commentOnDetails.on === 'theory_video') {
            return {
                eot: 'theory',
                eotId: commentOnDetails.theoryId,
            }
        } else {
            assertNever(commentOnDetails)
        }
    }

    const getEotId = (commentOnDetails: CommentOnDetails): { eot: ExerciseOrTheory, eotId: string } => {
        if (
            commentOnDetails.on === 'exercise_part' ||
            commentOnDetails.on === 'exercise_video' ||
            commentOnDetails.on === 'theory_part' ||
            commentOnDetails.on === 'theory_video'
        ) {
            return getEotId_helper(commentOnDetails)
        } else if (commentOnDetails.on === 'comment') {
            return getEotId_helper(commentOnDetails.replyInCommentDetails)
        } else {
            assertNever(commentOnDetails)
        }
    }

    if (notifications.notifications.length === 0) {
        return <h4>Still no notifications</h4>
    } else {
        return <div>
            {notifications.notifications.map(notification => {
                return <span className="dropdown-link" key={notification.id}>
                    <div
                        className="media"
                        onClick={() => {
                            const {
                                eot,
                                eotId,
                            } = getEotId(notification.notificationTypeAndDetails.commentOnDetails)

                            const queryStringStringified = queryString.stringify({ notification: notification.id })
                            navigate(`/${eot}/${eotId}?${queryStringStringified}`)
                        }}
                    >
                        <img src="http://via.placeholder.com/500x500" alt="" />
                        <div className="media-body">
                            <p>
                                <strong>bla bla</strong> you have response to your comment
                                "{notification.notificationTypeAndDetails.commentPreview}"
                            </p>
                            <span>October 03, 2017 8:45am</span>
                        </div>
                    </div>
                </span>
            })}
        </div>
    }
}
