import React from 'react'
import { IsbnParsed } from '../utils/Common/Common'
import { queryServer } from '../utils/queryServer'

export const useAllowedIsbns = () => {
    const [allIsbns, setAllIsbns] = React.useState<IsbnParsed[]>()

    React.useEffect(() => {
        getAllowedIsbns()
    }, [])

    const getAllowedIsbns = async () => {
        const response = await queryServer(
            'getAllowedIsbns',
            undefined,
        )

        setAllIsbns(response.data)
    }

    return allIsbns
}
