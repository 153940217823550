import { nanoid } from 'nanoid'
import React from 'react'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import {
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap'
import { assertNever, check } from '../utils/utilFunctions'
import { queryServer } from '../utils/queryServer'
import * as _ from "lodash"

type Props = {
    gradesForSubject: RequestResponseTypes["getGradesForSubject"]["response"]
    setGradesForSubject: React.Dispatch<React.SetStateAction<RequestResponseTypes["getGradesForSubject"]["response"] | undefined>>
    subjectId: string
}

type GradeWithStatus = {
    id: string
    grade_in_local: string
    order_number: number
    grade_description: string
    status: 'saved' | 'shouldDelete' | 'shouldSave'
}

export const SubjectAuthority_Grade = ({ gradesForSubject, subjectId, setGradesForSubject }: Props) => {
    const [grades, setGrades] = React.useState<GradeWithStatus[]>([])

    React.useEffect(() => {
        setGrades(gradesForSubject.grades.map(g => ({
            ...g,
            status: 'saved',
        })))
    }, [gradesForSubject])

    const resetAddNewGradeForm = () => {
        return {
            grade_in_local: '',
            order_number: -1,
            grade_description: '',
        }
    }

    const [addNewGrade, setAddNewGrade] = React.useState(resetAddNewGradeForm())

    const saveOrganization = async () => {
        const grades_: RequestResponseTypes["saveGradesForSubject"]["request"]['grades'] = []
        grades.forEach(g => {
            if (g.status === 'shouldDelete') {
                grades_.push({
                    ...g,
                    todo: 'delete',
                })
            } else if (g.status === 'shouldSave') {
                grades_.push({
                    ...g,
                    todo: 'save',
                })
            }
        })
        const newGradeOrganization = await queryServer(
            'saveGradesForSubject',
            {
                subjectId,
                grades: grades_,
            },
        )

        setGradesForSubject(newGradeOrganization)
        location.reload()
    }

    return <div className="section-wrapper grade-managerr">
        <div>
            {_.sortBy(grades, (grade) => grade.order_number)
                .map(grade => {
                    let color
                    if (grade.status === 'shouldDelete') {
                        color = "red"
                    } else if (grade.status === 'shouldSave') {
                        color = "green"
                    } else {
                        color = "black"
                    }
                    return <div key={grade.id} style={{ color }}>
                        {grade.order_number} {grade.grade_in_local} {grade.grade_description.length > 0 && ` (${grade.grade_description})`} &nbsp; &nbsp; <span
                            style={{ color: "purple" }}
                            onClick={() => {
                                setGrades(prev => {
                                    return prev
                                        .filter(it => !(it.id === grade.id && it.status === 'shouldSave'))
                                        .map(it => {
                                            if (it.id === grade.id) {
                                                check(it.status !== 'shouldSave')
                                                if (it.status === 'saved') {
                                                    const newItem: GradeWithStatus = {
                                                        ...it,
                                                        status: "shouldDelete",
                                                    }
                                                    return newItem
                                                } else if (it.status === 'shouldDelete') {
                                                    const newItem: GradeWithStatus = {
                                                        ...it,
                                                        status: "saved",
                                                    }
                                                    return newItem
                                                } else {
                                                    assertNever(it.status)
                                                }
                                            } else {
                                                return it
                                            }
                                        })
                                })
                            }}
                        >
                            Delete (grade should be empty (without organization) to be able to delete)
                        </span>
                    </div>
                })}
        </div>
        <div>
            <FormGroup>
                <Label>Grade in local</Label>
                <Input
                    type="text"
                    placeholder="grade in local..."
                    onChange={(e) => setAddNewGrade({
                        ...addNewGrade,
                        grade_in_local: e.target.value,
                    })}
                    value={addNewGrade.grade_in_local}
                />
            </FormGroup>
            <FormGroup>
                <Label>Grade description</Label>
                <Input
                    type="text"
                    placeholder="grade description..."
                    onChange={(e) => setAddNewGrade({
                        ...addNewGrade,
                        grade_description: e.target.value,
                    })}
                    value={addNewGrade.grade_description}
                />
            </FormGroup>
            <FormGroup>
                <Label>Order number</Label>
                <Input
                    type="number"
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    placeholder="order number..."
                    onChange={(e) => setAddNewGrade({
                        ...addNewGrade,
                        order_number: +e.target.value,
                    })}
                    value={addNewGrade.order_number}
                />
            </FormGroup>
            <Button
                onClick={() => {
                    check(
                        addNewGrade.grade_in_local !== '' &&
                        addNewGrade.order_number !== -1,
                        'llkHsSd'
                    )
                    setGrades(prev => prev.concat({
                        ...addNewGrade,
                        id: nanoid(),
                        status: 'shouldSave',
                    }))
                    setAddNewGrade(resetAddNewGradeForm())
                }}
            >
                Add grade
            </Button>
            <Button onClick={saveOrganization}>Save grades organization</Button>
        </div>
    </div>
}
