import React from 'react'
import { Eots_WebView } from './Eots_WebView'
import { SavedCollections_WebView } from './SavedCollections_WebView'

export const EotsAndSavedCollectionsPage = () => {
    const [activeTab, setActiveTab] = React.useState<'exercises' | 'saved_collections'>('exercises')

    return <div className="eots-and-saved-collections no-highlightable-text">
        <div className='eots-and-saved-collections-inner'>
            <div className='tab-nav'>
                <div
                    onClick={() => {
                        setActiveTab('exercises')
                        window.scrollTo({ top: 0 })
                    }}
                    className={`tab-btn left ${activeTab === 'exercises' ? 'active' : ''}`}
                >
                    Zadaci
                </div>
                <div
                    onClick={() => {
                        setActiveTab("saved_collections")
                        window.scrollTo({ top: 0 })
                    }}
                    className={`tab-btn right ${activeTab === 'saved_collections' ? 'active' : ''}`}
                >
                    Sačuvane kolekcije
                </div>
            </div>
            <Eots_WebView active={activeTab === 'exercises'} />
            <SavedCollections_WebView active={activeTab === 'saved_collections'} />
        </div>
    </div>
}
