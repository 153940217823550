//
// This file exists both on client and native
//
import React, { useRef, useState } from "react"
import { assertNever, check, checkNotNull } from "./utilFunctions"
import { nanoid } from "nanoid"
import { ExerciseInOptimization } from "./Common/Common"
import { queryServer } from "./queryServer"
import { uploadFile } from "./uploadFile"
import { RequestResponseTypes } from "./Common/RequestResponseTypes"
import { sortBy } from "lodash"

export type ProcessImageResult = {
    status: "our_solution"
    exercise: RequestResponseTypes['getExercise']['response']
    exerciseMatch: number
    ocrMatch: number
    avgMatch: number
} | {
    status: "ai_solution"
    solution: string
} | {
    status: "error"
    message: string
}

type ImageMetadata = {
    imgURL: string
    width: number | undefined
    height: number | undefined
}

type ProcessingStatus = {
    status: "in_progress"
    processId: string
    image: ImageMetadata
} | {
    status: "done"
    processId: string
    image: ImageMetadata
    result: ProcessImageResult
}

type Point = { x: number, y: number }

type NormalizedOcrResponse = {
    text: string
    vertices: Point[]
}[]

export const useImageProcessor = (
    renderLoader: () => React.ReactNode,
    renderResult: (result: ProcessImageResult) => React.ReactNode,
    renderImage: (image: ImageMetadata) => React.ReactNode,
) => {
    // probably should exist "both"
    const [algorithm, setAlgorithm] = useState<'ai' | 'vision'>("vision")

    const [_, setDummy] = useState(0)

    // undefined: first processing has not started yet
    const processingStatusRef = useRef<ProcessingStatus | undefined>()

    const setRefAndRerender = (data: {
        todo: "start_new"
        image: ImageMetadata
        processId: string
    } | {
        todo: "done"
        result: ProcessImageResult
    }) => {
        if (data.todo === "start_new") {
            processingStatusRef.current = {
                status: "in_progress",
                image: data.image,
                processId: data.processId,
            }
        } else if (data.todo === "done") {
            check(processingStatusRef.current?.status === "in_progress", "nB6TA")
            processingStatusRef.current = {
                status: "done",
                result: data.result,
                image: processingStatusRef.current.image,
                processId: processingStatusRef.current.processId,
            }
        } else {
            assertNever(data)
        }

        setDummy(prev => prev + 1)
    }

    const process = async (image: ImageMetadata) => {
        const processId = nanoid()

        setRefAndRerender({
            todo: "start_new",
            image,
            processId,
        })

        const [
            processImageOptimizations,
            uploadedImage,
        ] = await Promise.all([
            getProcessImageOptimizations(),
            uploadImage(image),
        ])

        if (processingStatusRef.current!.processId !== processId) {
            // another process is called in meanwhile, so do nothing in this case
            return
        }

        if (uploadedImage == null) {
            setRefAndRerender({
                todo: "done",
                result: {
                    status: "error",
                    message: "Error while uploading an image",
                },
            })
            return
        }

        if (algorithm === "vision") {
            const visionWords = await getVisionWords(uploadedImage.fileId)

            if (processingStatusRef.current!.processId !== processId) {
                // another process is called in meanwhile, so do nothing in this case
                return
            }

            if (visionWords == null) {
                setRefAndRerender({
                    todo: "done",
                    result: {
                        status: "error",
                        message: "Vision problem. Text can't be extracted from the image",
                    },
                })
                return
            }

            const matchData = processImageOptimizations
                .map(([exerciseId, data]) => {
                    const {
                        words1Match: exerciseMatch,
                        words2Match: ocrMatch,
                    } = compareWords(data, visionWords)

                    return {
                        exerciseId: exerciseId,
                        exerciseMatch,
                        ocrMatch,
                        avgMatch: (exerciseMatch + ocrMatch) / 2,
                    }
                })

            const sortedByBestMatch = sortBy(
                matchData,
                [(it) => -it.avgMatch],
            )

            const bestMatches = sortedByBestMatch.slice(0, 3)

            if (bestMatches.length === 0) {
                setRefAndRerender({
                    todo: "done",
                    result: {
                        status: "error",
                        message: "No best matches",
                    },
                })
                return
            }

            const bestMatch = bestMatches[0]

            const response = await queryServer(
                'getExercise',
                {
                    exerciseId: bestMatch.exerciseId,
                    notificationId: undefined,
                    openCommentId: undefined,
                },
            )

            setRefAndRerender({
                todo: "done",
                result: {
                    status: "our_solution",
                    exercise: response,
                    avgMatch: bestMatch.avgMatch,
                    exerciseMatch: bestMatch.exerciseMatch,
                    ocrMatch: bestMatch.ocrMatch,
                },
            })
        } else if (algorithm === "ai") {
            const aiSolution = await queryServer(
                'solveExerciseWithAi',
                {
                    uploadedImageId: uploadedImage.fileId,
                },
            )

            if (aiSolution.solution == null) {
                setRefAndRerender({
                    todo: "done",
                    result: {
                        status: 'error',
                        message: "_AI_error_"
                    },
                })
            } else {
                setRefAndRerender({
                    todo: "done",
                    result: {
                        status: "ai_solution",
                        solution: aiSolution.solution,
                    },
                })
            }
        } else {
            assertNever(algorithm)
        }
    }

    const render = (): React.ReactNode => {
        if (processingStatusRef.current == null) {
            return null
        } else if (processingStatusRef.current.status === "in_progress") {
            return <>
                {renderImage(processingStatusRef.current.image)}
                {renderLoader()}
            </>
        } else if (processingStatusRef.current.status === "done") {
            return <>
                {renderImage(processingStatusRef.current.image)}
                {renderResult(processingStatusRef.current.result)}
            </>
        } else {
            assertNever(processingStatusRef.current)
        }
    }

    return {
        render,
        process,
        algorithm,
        setAlgorithm,
    }
}

const getProcessImageOptimizations = async (): Promise<ExerciseInOptimization[]> => {
    const { data } = await queryServer("getProcessImageOptimizations", undefined)
    return data
}

const uploadImage = async (
    image: ImageMetadata,
): Promise<{ fileUrl: string, fileId: string } | null> => {
    const blobResponse = await fetch(image.imgURL)
    const blob = await blobResponse.blob()
    const file = new File([blob], "unused_name_blab", { type: blob.type })
    return await uploadFile(file, "process-image-1")
}

const getVisionWords = async (uploadedImageId: string): Promise<string[] | null> => {
    const ocrResponse = await queryServer("processVisionOcr", { uploadedImageId })

    if (!ocrResponse.success) {
        return null
    }

    const normalizedOcrResponse = normalizeOcrResponse(ocrResponse)

    if (normalizedOcrResponse == null) {
        return null
    }

    const withoutDuplicates = withoutSubsetQuadrilaterals(normalizedOcrResponse)

    const ocrWords = withoutDuplicates
        .map(it => it.text)
        .join(" ")
        .split(" ")
        .map(it => it.trim())
        .filter(it => it.length > 0)

    return ocrWords
}

function normalizeOcrResponse(
    data: RequestResponseTypes["processVisionOcr"]["response"],
): NormalizedOcrResponse | null {
    if (!data.success || data.result == null || data.result.length === 0) {
        return null
    }

    const normalizedOcrResponse: NormalizedOcrResponse = []

    data.result.forEach(it => {
        if (
            // if everything is OK, push to normalizedOcrResponse
            it.text != null
            && it.vertices != null
            && it.vertices.length === 4
            && it.vertices.find(v => v.x == null || v.y == null) == null
        ) {
            normalizedOcrResponse.push({
                text: it.text,
                vertices: it.vertices.map(v => ({
                    x: checkNotNull(v.x, 'noXDf'),
                    y: checkNotNull(v.y, 'noYqP'),
                }))
            })
        }
    })

    if (normalizedOcrResponse.length === 0) {
        return null
    } else {
        return normalizedOcrResponse
    }
}

// Done using ChatGPT (used ray-casting algorithm + something like https://stackoverflow.com/a/29915728)
function pointIsInPolygon(point: Point, polygon: Point[]): boolean {
    let inside = false
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].x, yi = polygon[i].y
        const xj = polygon[j].x, yj = polygon[j].y

        // Check if the point is on the edge
        if ((point.y - yi) * (xj - xi) === (point.x - xi) * (yj - yi) &&
            Math.min(xi, xj) <= point.x && point.x <= Math.max(xi, xj) &&
            Math.min(yi, yj) <= point.y && point.y <= Math.max(yi, yj)) {
            return true
        }

        const intersect = ((yi > point.y) !== (yj > point.y))
            && (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi)
        if (intersect) inside = !inside
    }
    return inside
}

function sortVertices(vertices: Point[]): Point[] {
    const centroid = vertices.reduce((acc, { x, y }) => ({ x: acc.x + x / vertices.length, y: acc.y + y / vertices.length }), { x: 0, y: 0 })
    return vertices.sort((a, b) => Math.atan2(a.y - centroid.y, a.x - centroid.x) - Math.atan2(b.y - centroid.y, b.x - centroid.x))
}

// for now if quadrilaterals are the same, it will also return 'true'
function firstQuadrilateralIsSuperset(
    firstQuadrilateral: Point[],
    secondQuadrilateral: Point[],
): boolean {
    const sortedQuad1 = sortVertices(firstQuadrilateral)

    return secondQuadrilateral.find(point => !pointIsInPolygon(point, sortedQuad1)) == null
}

// should be checked, but it's ok for now
function withoutSubsetQuadrilaterals(
    normalizedOcrResponse: NormalizedOcrResponse
): NormalizedOcrResponse {
    const result = normalizedOcrResponse.map(it => ({
        ...it,
        toRemove: false,
    }))

    result.forEach((it, index) => {
        if (!it.toRemove) {
            result.forEach((it2, index2) => {
                if (index !== index2 && firstQuadrilateralIsSuperset(it.vertices, it2.vertices)) {
                    it2.toRemove = true
                }
            })
        }
    })

    const notRemoved = new Map<string, number>()
    const removed = new Map<string, number>()
    result.forEach(it => {
        if (it.toRemove) {
            for (const character of it.text) {
                const countFromResult = removed.get(character) || 0
                removed.set(character, countFromResult + 1)
            }
        } else {
            for (const character of it.text) {
                const countFromResult = notRemoved.get(character) || 0
                notRemoved.set(character, countFromResult + 1)
            }
        }
    })
    removed.forEach((countFromRemoved, char) => {
        const countFromNotRemoved = notRemoved.get(char)
        // TODO: CHECK THIS FOLLOWING CHECK, IT BEAKS !!!!!!!!!!!!!!!! WHOLE LOGIC CHECK ABOUT THESE QUADS ETC.
        // TODO: CHECK THIS FOLLOWING CHECK, IT BEAKS !!!!!!!!!!!!!!!! WHOLE LOGIC CHECK ABOUT THESE QUADS ETC.
        // TODO: CHECK THIS FOLLOWING CHECK, IT BEAKS !!!!!!!!!!!!!!!! WHOLE LOGIC CHECK ABOUT THESE QUADS ETC.
        // TODO: CHECK THIS FOLLOWING CHECK, IT BEAKS !!!!!!!!!!!!!!!! WHOLE LOGIC CHECK ABOUT THESE QUADS ETC.
        // check(countFromNotRemoved != null && countFromNotRemoved === countFromRemoved, `iK8876TgH ${countFromNotRemoved} ${countFromRemoved}`)
        // console.log(`OK check for >>>>>> [${char}, ${countFromNotRemoved}] <<<<<<<`)
    })

    // TO INVESTIGATE, WHY IT GIVES SOME TIMES MORE THAN 1 .....................
    // TO INVESTIGATE, WHY IT GIVES SOME TIMES MORE THAN 1 .....................
    // TO INVESTIGATE, WHY IT GIVES SOME TIMES MORE THAN 1 .....................
    // TO INVESTIGATE, WHY IT GIVES SOME TIMES MORE THAN 1 .....................
    // console.log('####################### n left quadrilaterals:::::::::::::::::::::::::::::::::::::::::::::: ', result.filter(it => !it.toRemove).length)

    return result.filter(it => !it.toRemove)
}

const compareWords = (
    words1CharactersArr: [string, number][],
    words2: string[],
): { words1Match: number, words2Match: number } => {
    const words1CharactersMap = new Map(words1CharactersArr)
    const words2CharactersMap = wordsToCharactersMap(words2)

    words1CharactersArr.forEach(([character, countIn1]) => {
        const countIn2 = words2CharactersMap.get(character)
        if (countIn2 != null) {
            const min = Math.min(countIn1, countIn2)
            // update counts in maps
            words1CharactersMap.set(character, countIn1 - min)
            words2CharactersMap.set(character, countIn2 - min)
        }
    })

    let words1OriginalLength = 0
    words1CharactersArr.forEach(([_, count]) => words1OriginalLength += count)
    let words1RemainingLength = 0
    words1CharactersMap.forEach(count => words1RemainingLength += count)
    const words1Match = (words1OriginalLength - words1RemainingLength) / words1OriginalLength

    let words2OriginalLength = 0
    words2.forEach(w => words2OriginalLength += w.length)
    let words2RemainingLength = 0
    words2CharactersMap.forEach(count => words2RemainingLength += count)
    const words2Match = (words2OriginalLength - words2RemainingLength) / words2OriginalLength

    check(0 <= words1Match && words1Match <= 1
        && 0 <= words2Match && words2Match <= 1, "nuq77Uq")

    return {
        words1Match,
        words2Match,
    }
}

function wordsToCharactersMap(words: string[]): Map<string, number> {
    const result = new Map<string, number>()

    words.forEach(word => {
        for (const character of word) {
            const countFromResult = result.get(character) || 0
            result.set(character, countFromResult + 1)
        }
    })

    return result
}
