import React from 'react'
import { ExerciseInformationsForGrade, TestInGrade, EotPart } from '../utils/Common/Common'
import { assertNever, check, checkNotNull, nowSecondsUTC } from '../utils/utilFunctions'
import { Badge, FormGroup, Label } from 'reactstrap'
import { EotTextInList } from '../utils/EotTextInList'
import { useNavigate } from 'react-router-dom'
import * as _ from "lodash"
import Select from 'react-select'
import { useLocalLanguage } from '../utils/useLocalLanguage'
import { LAST_FILTERS } from './LastFilters'
import { Loader } from '../utils/Loader'
import { parseRegularAndLatexWords } from '../Contribute/LatexPart'
import { FindExercise_Tests } from './FindExercise_Tests'

type Props = {
    exercise: ExerciseInformationsForGrade
    exerciseTests: TestInGrade[] | undefined
}

const N_EXERCISES_PER_PAGE = 10

// initial version: "an apple" : a->2, an->1, ap->1, app->1, appl->1, apple->1
export function calculateKeywordsMap(
    eotText: EotPart[] | undefined,
    // additionalText should be space-separated words
    additionalText: string | undefined,
): Map<string, number> {
    const words: string[] = []

    eotText?.forEach(eotPart => {
        if (eotPart.content.type === 'text') {
            eotPart.content.text
                .replace(/\n/g, " ")
                .split(" ")
                .map(it => it.trim().toLowerCase())
                .forEach(it => words.push(it))
        }
    })

    additionalText
        ?.replace(/\n/g, " ")
        .split(" ")
        .map(it => it.trim().toLowerCase())
        .forEach(it => words.push(it))

    const result: Map<string, number> = new Map()

    words.forEach(word => {
        let keeper = ''
        for (const character of word) {
            keeper += character

            const countFromResult = result.get(keeper) || 0
            result.set(keeper, countFromResult + 1)
        }
    })

    return result
}

// version 2: it looks characters (makes map from characters)
function calculateKeywordsMap2(
    eotText: EotPart[] | undefined,
    additionalText: string | undefined,
): Map<string, number> {
    const result: Map<string, number> = new Map()

    const processCharacter = (character: string) => {
        const formatedCharacter = character.trim().toLowerCase()
        if (formatedCharacter !== "") {
            const countFromResult = result.get(formatedCharacter) || 0
            result.set(formatedCharacter, countFromResult + 1)
        }
    }

    eotText?.forEach(eotPart => {
        if (eotPart.content.type === 'text') {
            for (const character of eotPart.content.text) {
                processCharacter(character)
            }
        }
    })

    if (additionalText != null) {
        for (const character of additionalText) {
            processCharacter(character)
        }
    }

    return result
}

function adjustLatexWords(latexWords: string) {
    let adjustedLatexWords = latexWords

    //
    //
    // *** replace some words
    const replace: [string, string][] = [
        ["\cdot", "*"],
        ["\dfrac", "/"],
        ["\mid", ""],
        ["\bigg", ""],
        ["\sqrt", ""],
        ["\quad", ""],
        ["\sqr", ""],
        ["\right", ""],
        ["\left", ""],
        ["\begin", ""],
        ["\end", ""],
        ["{eqnarray}", ""],
        ["\hline", ""],
        ["\qquad", ""],
        ["\color{", ""],
        ["\circ", ""],
        ["{array}", ""],
        ["|c|", ""],
    ]
    replace.forEach(([replace, replaceWith]) => {
        const regex = new RegExp(replace, 'g')
        adjustedLatexWords = adjustedLatexWords.replace(regex, replaceWith)
    })

    //
    //
    // *** for each "." add "," and vice versa
    let commas = ''
    let fullstops = ''
    for (const character of adjustedLatexWords) {
        if (character === '.') {
            commas += ','
        } else if (character === ',') {
            fullstops += '.'
        }
    }
    adjustedLatexWords += commas
    adjustedLatexWords += fullstops

    //
    //
    // *** for each \alpha add f (because of 'alfa')
    var nAlphas = (adjustedLatexWords.match(/\alpha/g) || []).length
    for (let i = 1; i <= nAlphas; i++) {
        adjustedLatexWords += "f"
    }

    //
    //
    // *** remove white spaces
    adjustedLatexWords = adjustedLatexWords
        .split(" ")
        .map(it => it.trim())
        .filter(it => it.length > 0)
        .join(" ")
        .trim()

    return adjustedLatexWords
}

// character -> also can be searched with
const criticalCharactersForSearch = new Map([
    ["č", "c"],
    ["ć", "c"],
    ["đ", "dj"],
    ["š", "s"],
    ["ž", "z"],
])

// uses both calculateKeywordsMap (initial logic) and calculateKeywordsMap2 (parsing by character)
function calculateEotKeywords3(
    eotText: EotPart[] | undefined,
    // additionalText should be space-separated words
    additionalText: string | undefined,
): { regularWords: Map<string, number>, latexCharacters: Map<string, number> } {
    let strRaw = ''

    eotText?.forEach(eotPart => {
        if (eotPart.content.type === 'text') {
            strRaw += `${eotPart.content.text} `
        }
    })

    if (additionalText != null) {
        strRaw += `${additionalText} `
    }

    const parsed = parseRegularAndLatexWords(strRaw)

    let adjustedLatexWords = adjustLatexWords(parsed.latexWords)

    // each word that has some of critical characters inside remove from regular words, and add it to
    // adjustedLatexWords. And also add its values from "criticalCharactersForSearch" object to adjustedLatexWords
    const adjustedRegularWords = parsed.regularWords
        .split(" ")
        .filter(word => {
            let criticalCharactersCanBeSearchedBy = ''

            for (const character of word) {
                const alsoCanBeSearchedBy = criticalCharactersForSearch.get(character)
                if (alsoCanBeSearchedBy != null) {
                    criticalCharactersCanBeSearchedBy += `${alsoCanBeSearchedBy}`
                }
            }

            if (criticalCharactersCanBeSearchedBy !== '') {
                adjustedLatexWords += `${word} ${criticalCharactersCanBeSearchedBy} `
            }

            return criticalCharactersCanBeSearchedBy === ''
        })
        .join(" ")

    return {
        regularWords: calculateKeywordsMap(undefined, adjustedRegularWords),
        latexCharacters: calculateKeywordsMap2(undefined, adjustedLatexWords),
    }
}

function calculateTypingKeywords(typingRaw: string): Map<string, number> {
    const typingWords = typingRaw
        .replace(/\n/g, " ")
        .split(" ")
        .map(it => it.trim().toLowerCase())
        .filter(word => word.length > 0)

    const result: Map<string, number> = new Map()

    typingWords.forEach(word => {
        const countFromResult = result.get(word) || 0
        result.set(word, countFromResult + 1)
    })

    return result
}

const typedWordPassingEotKeywords = (
    typedWord: string,
    typedFrequency: number,
    eotKeywords: { regularWords: Map<string, number>, latexCharacters: Map<string, number> },
): boolean => {
    let missing = ''

    // check if typed is passing regular eot keywords
    const frequencyInEotWords = eotKeywords.regularWords.get(typedWord) ?? 0
    if (frequencyInEotWords < typedFrequency) {
        for (let i = 1; i <= typedFrequency - frequencyInEotWords; i++) {
            missing += `${typedWord} `
        }
    } else {
        // typed is passed eot keywords successfuly
        return true
    }

    check(missing.length > 0, "SA665DN")

    // check if missing is passing eot latex characters
    const typingCharacters = [...calculateKeywordsMap2(undefined, missing)]
    for (const [typedCh, typedChFrequency] of typingCharacters) {
        const frequencyInEot = eotKeywords.latexCharacters.get(typedCh) ?? 0
        if (frequencyInEot < typedChFrequency) {
            return false
        }
    }

    return true
}

function getSelectedAreasRecursively(
    selectedAreaId: string | undefined,
    areaOrganization: {
        areaId: string
        parent_id: string | null
    }[],
): Set<string> | null {
    if (selectedAreaId == null) {
        return null
    }

    const result: Set<string> = new Set()

    let nRcursiveCalls = 0
    const fulfillChildrenRecursive = (areaId: string) => {
        nRcursiveCalls++
        check(nRcursiveCalls < 300, `too many recursive calls j78ja6hQW : ${nRcursiveCalls}`)

        result.add(areaId)

        areaOrganization.forEach(area => {
            if (area.parent_id === areaId) {
                fulfillChildrenRecursive(area.areaId)
            }
        })
    }

    fulfillChildrenRecursive(selectedAreaId)

    return result
}

export const FindExercise = ({ exercise, exerciseTests }: Props) => {
    const areasSortedAndMemoized = React.useMemo(() => {
        return _.sortBy(exercise.areaOrganization, (it) => it.order_number)
    }, [])

    const exercisesSortedAndMemoized = React.useMemo(() => {
        // copy exercises because the array will be sorted in place
        return _.cloneDeep(exercise.exercises)
            .map(exercise => {
                const areaIndex = areasSortedAndMemoized.findIndex(area => area.areaId === exercise.inAreaId)
                check(areaIndex !== -1, "cauwko")

                return {
                    ...exercise,
                    keywords: calculateEotKeywords3(exercise.publicText, exercise.additionalKeywords),
                    areaName: areasSortedAndMemoized[areaIndex].area_name,
                    areaIndex,
                }
            })
            .sort((a, b) => {
                const areaOrderNumber_a = checkNotNull(exercise.areaOrganization.find(it => it.areaId === a.inAreaId), 'd45RR5').order_number
                const areaOrderNumber_b = checkNotNull(exercise.areaOrganization.find(it => it.areaId === b.inAreaId), 'b4ffR5').order_number

                if (areaOrderNumber_a < areaOrderNumber_b) {
                    return -1
                } else if (areaOrderNumber_a > areaOrderNumber_b) {
                    return 1
                } else if (a.difficulty < b.difficulty) {
                    return -1
                } else if (a.difficulty > b.difficulty) {
                    return 1
                } else if (a.avgOrderNum < b.avgOrderNum) {
                    return -1
                } else if (a.avgOrderNum > b.avgOrderNum) {
                    return 1
                } else {
                    return a.lastUpdatedTs - b.lastUpdatedTs
                }
            })
            .map((exercise, index) => ({
                ...exercise,
                exerciseOrderNumberInList: index + 1
            }))
    }, [])

    const { inLocalLanguage } = useLocalLanguage()

    // filteredExercises shouldn't hold some data such as: keywords, inAreaId, etc.
    // But it is ok for now. Later can be fixed.
    const [filteredExercises, setFilteredExercises] = React.useState<{
        keywords: { regularWords: Map<string, number>, latexCharacters: Map<string, number> }
        id: string
        publicText: EotPart[]
        additionalKeywords: string
        difficulty: number
        inAreaId: string
        areaName: string
        areaIndex: number
        exerciseOrderNumberInList: number
        avgOrderNum: number
        lastUpdatedTs: number
    }[]>()

    const [page, setPage] = React.useState<number>()

    React.useEffect(() => {
        if (filteredExercises != null) {
            let initializePage
            if (LAST_FILTERS.exercisePage != null) {
                check(LAST_FILTERS.exercisePage >= 1, "MNNNmm26y")

                const totalPages = Math.ceil(filteredExercises.length / N_EXERCISES_PER_PAGE)

                if (LAST_FILTERS.exercisePage > totalPages) {
                    LAST_FILTERS.exercisePage = 1
                    initializePage = 1
                } else {
                    initializePage = LAST_FILTERS.exercisePage
                }
            } else {
                LAST_FILTERS.exercisePage = 1
                initializePage = 1
            }

            setPage(initializePage)
        }
    }, [filteredExercises == null])

    const DOTS = '...'
    const range = (start: number, end: number) => {
        let length = end - start + 1
        return Array.from({ length }, (_, idx) => idx + start)
    }

    const calcPaginationBalls = () => {
        if (filteredExercises == null || page == null) {
            return null
        }
        const totalPages = Math.ceil(filteredExercises.length / N_EXERCISES_PER_PAGE)

        if (totalPages <= 6) {
            return range(1, totalPages)
        }

        const leftPage = Math.max(page - 1, 1)
        const rightPage = Math.min(page + 1, totalPages)

        const showLeftDots = leftPage > 2
        const showRightDots = rightPage < totalPages - 1

        check(showLeftDots || showRightDots, '2fdsIUyu8')

        if (showLeftDots && showRightDots) {
            return [1, DOTS, ...range(leftPage, rightPage), DOTS, totalPages]
        } else if (showLeftDots) {
            return [1, DOTS, ...range(totalPages - 4, totalPages)]
        } else {
            return [...range(1, 5), DOTS, totalPages]
        }
    }

    //
    // filters down
    const [allExercisesOrTests, setAllExercisesOrTests] = React.useState<{ option: "allExercises" | "testsExercises" } | null>(() => {
        if (LAST_FILTERS.allExercisesOrTests != null) {
            const testsExercises = LAST_FILTERS.allExercisesOrTests === "testsExercises"
                && exercise.exercises.length > 0
                && exerciseTests != null
                && exerciseTests.length > 0

            return { option: testsExercises ? "testsExercises" : "allExercises" }
        } else {
            return null
        }
    })

    const [chosenArea, setChosenArea] = React.useState<{
        areaId: string,
        area_name: string,
        deep_level: number,
    } | null>(() => {
        if (LAST_FILTERS.exerciseArea != null) {
            const checkedChosenArea = exercise.areaOrganization
                .find(it => it.areaId === LAST_FILTERS.exerciseArea)

            if (checkedChosenArea == null) {
                LAST_FILTERS.exerciseArea = undefined
                return null
            } else {
                return {
                    areaId: checkedChosenArea.areaId,
                    area_name: checkedChosenArea.area_name,
                    deep_level: checkedChosenArea.deep_level,
                }
            }
        } else {
            return null
        }
    })
    const [typingKeywords, setTypingKeywords] = React.useState(LAST_FILTERS.exerciseKeywords ?? '')

    const [difficulty, setDifficulty] = React.useState<{ option: 'easy' | 'medium' | 'hard' } | null>(() => {
        if (LAST_FILTERS.exerciseDifficulty != null) {
            return { option: LAST_FILTERS.exerciseDifficulty }
        } else {
            return null
        }
    })

    // filters up
    //

    const changePage = (pageNum: number) => {
        LAST_FILTERS.exercisePage = pageNum
        setPage(pageNum)
        window.scrollTo({ top: 0 })
    }

    React.useEffect(() => {
        const selectedRecursiveAreas = getSelectedAreasRecursively(chosenArea?.areaId, exercise.areaOrganization)
        const typedKeywordAndFrequency = [...calculateTypingKeywords(typingKeywords)]

        const newFilteredExercises = exercisesSortedAndMemoized.filter(exercise => {
            // check if exercise passes selected area
            if (selectedRecursiveAreas != null && !selectedRecursiveAreas.has(exercise.inAreaId)) {
                return false
            }

            // check if exercise passes selected difficulty
            if (difficulty != null) {
                const chosenDifficulty = difficulty.option

                const passesDifficultyFilter = chosenDifficulty === 'easy' && exercise.difficulty < 4
                    || chosenDifficulty === 'medium' && 4 <= exercise.difficulty && exercise.difficulty <= 7
                    || chosenDifficulty === 'hard' && exercise.difficulty > 7

                if (!passesDifficultyFilter) {
                    return false
                }
            }

            // check if exercise passes keywords
            for (const [typedKeyword, typedFrequency] of typedKeywordAndFrequency) {
                const ok = typedWordPassingEotKeywords(
                    typedKeyword,
                    typedFrequency,
                    exercise.keywords,
                )
                if (!ok) {
                    return false
                }
            }

            return true
        })

        setFilteredExercises(newFilteredExercises)
    }, [chosenArea?.areaId, difficulty, typingKeywords])

    const navigate = useNavigate()

    if (filteredExercises == null || page == null) {
        return <Loader />
    }

    return <div className='mg-t-20'>
        {exercise.exercises.length > 0
            && exerciseTests != null
            && exerciseTests.length > 0
            && <FormGroup>
                <Label style={{ marginBottom: "2px" }}>{inLocalLanguage("All exercises")} / {inLocalLanguage("Tests")}</Label>
                {/* delete this block after 1. july (1719788400 = 30. jun) */}
                {nowSecondsUTC() < 1719788400 &&
                    <Badge
                        pill
                        className='ml-2'
                        style={{ backgroundColor: '#00cc66', verticalAlign: 'middle' }}
                    >
                        Novo
                    </Badge>
                }
                <Select
                    isSearchable={false}
                    options={[
                        { option: "allExercises" },
                        { option: "testsExercises" },
                    ]}
                    value={allExercisesOrTests}
                    getOptionLabel={(option) => {
                        if (option.option === "allExercises") {
                            return inLocalLanguage("All exercises")
                        } else if (option.option === "testsExercises") {
                            return inLocalLanguage("Tests")
                        } else {
                            assertNever(option.option)
                        }
                    }}
                    onChange={option => {
                        LAST_FILTERS.allExercisesOrTests = option?.option
                        setAllExercisesOrTests(option ?? null)
                    }}
                    isOptionSelected={option => option.option === allExercisesOrTests?.option}
                    placeholder={`${inLocalLanguage("All exercises")} / ${inLocalLanguage("Tests")}`}
                    isClearable={false}
                    styles={{
                        control: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                        option: (base, r) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                    }}
                />
            </FormGroup>}
        {allExercisesOrTests?.option === "testsExercises" ? <FindExercise_Tests
            tests={checkNotNull(exerciseTests, "NmY7r")}
            exercise={exercise}
        /> : <>
            <FormGroup>
                <Label style={{ marginBottom: "2px" }}>{inLocalLanguage("Area")}</Label>
                <Select
                    isSearchable={false}
                    options={areasSortedAndMemoized}
                    value={chosenArea}
                    getOptionLabel={(option) => option.area_name}
                    onChange={option => {
                        LAST_FILTERS.exerciseArea = option?.areaId
                        LAST_FILTERS.exercisePage = 1
                        setPage(1)
                        setChosenArea(option ?? null)
                    }}
                    isOptionSelected={option => option.areaId === chosenArea?.areaId}
                    placeholder={`${inLocalLanguage("Chose")} ${inLocalLanguage("Area").toLowerCase()}`}
                    isClearable
                    styles={{
                        control: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                        option: (base, r) => ({
                            ...base,
                            paddingLeft: `${r.data.deep_level * 10 + 6}px`,
                            fontSize: "16px",
                        }),
                    }}
                />
            </FormGroup>
            <FormGroup>
                <Label style={{ marginBottom: "2px" }}>{inLocalLanguage("Difficulty")}</Label>
                <Select
                    isSearchable={false}
                    options={[{ option: 'easy' }, { option: 'medium' }, { option: 'hard' }]}
                    value={difficulty}
                    getOptionLabel={option => inLocalLanguage(option.option)}
                    onChange={option => {
                        LAST_FILTERS.exerciseDifficulty = option?.option
                        LAST_FILTERS.exercisePage = 1
                        setPage(1)
                        setDifficulty(option ?? null)
                    }}
                    placeholder={`${inLocalLanguage("Chose")}`}
                    isOptionSelected={option => option.option === difficulty?.option}
                    isClearable
                    styles={{
                        control: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                        option: (base) => ({
                            ...base,
                            fontSize: "16px",
                        }),
                    }}
                />
            </FormGroup>
            <div style={{ 'marginTop': '20px', 'marginBottom': '30px' }}>
                <div style={{ marginBottom: "2px" }}>{inLocalLanguage("Keywords")}</div>
                <input
                    style={{ fontSize: '16px' }}
                    type="string"
                    placeholder={inLocalLanguage("Find_exercise_by_keywords")}
                    value={typingKeywords}
                    className="form-control"
                    onChange={(e) => {
                        LAST_FILTERS.exerciseKeywords = e.target.value
                        LAST_FILTERS.exercisePage = 1
                        setPage(1)
                        setTypingKeywords(e.target.value)
                    }}
                />
            </div>
            <hr style={{ margin: '46px 0', borderWidth: "2px" }} />

            <div className='mg-b-50'>
                {filteredExercises
                    .filter((_, index) => {
                        const showFromIndex = (page - 1) * N_EXERCISES_PER_PAGE
                        const showToIndex = page * N_EXERCISES_PER_PAGE - 1

                        return showFromIndex <= index && index <= showToIndex
                    })
                    .map(e => {
                        return <EotTextInList
                            eotText={e.publicText}
                            difficulty={e.difficulty}
                            area={{ areaName: e.areaName, areaIndex: e.areaIndex }}
                            exerciseOrderNumberInList={e.exerciseOrderNumberInList}
                            onClick={() => navigate(`/exercise/${e.id}`)}
                            key={e.id}
                        />
                    })}
            </div>
            <hr />
            <div className='mg-t-30' style={{
                display: "flex",
                alignItems: 'center',
                justifyContent: "center",
            }}>
                <ul className="pagination pagination-circle">
                    {calcPaginationBalls()?.map((element, i) => {
                        if (typeof element === 'string') {
                            return <li
                                style={{ margin: 0 }}
                                key={i}
                                className="page-item d-flex align-items-center"
                            >
                                {DOTS}
                            </li>
                        } else {
                            check(typeof element === 'number', 'afds7766')

                            return <li
                                key={i}
                                className={`page-item ${page === element ? 'active' : ''} `}
                                style={{ cursor: 'pointer', margin: 0 }}
                            >
                                <a href='#' onClick={() => changePage(element)} className='page-link'>
                                    {element}
                                </a>
                            </li>
                        }
                    })}
                </ul>
            </div>
        </>}
    </div>
}
